import React, {useMemo, useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './sidenav'
import DataService from "./services/Events";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {
	Container,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import TableContainer from "./tablecontainer"
import { SelectColumnFilter } from './filters';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

export default function AddProducts() {

	const [isError, setIsError] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	const [isSucess, setIsSucess] = useState(false);
	const [addProduct, setAddProduct] = useState(false);
	const [message, setMessage] = useState(false);
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [units, setUnits] = useState("");
	const [image, setImage] = useState(null);
	const [price, setPrice] = useState("");
    const [imgData, setImgData] = useState(null);
    const [products, setProducts] = useState([]);

	useEffect(() => {
       
		DataService.getProducts()
		.then(result => {

		if (result.status === 200) {

            setProducts(result.data.data);
		} 
		else {
			setIsError(true);
		}
		}).catch(e => {
		  setIsError(true);
		});

      }, []);


    //Add Ecommerce Products 
    const postProducts = (e) => {
		e.preventDefault();

        let formData = new FormData() 
		formData.append('image', image) 
        formData.append('name', name) 
		formData.append('description', description) 
		formData.append('units', units) 
		formData.append('price', price)
		
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        setDisasbled(true);

		DataService.addProducts(formData, config)
		.then(result => {

			if (result.status === 201) {			

                setMessage(result.data.message)
                setIsSucess(true);

                setDisasbled(false);

                setAddProduct(true);
                toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

            } 
            else {
                setMessage(result.data.message)
                setIsError(true);
                setDisasbled(false)
                toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

            }
		}).catch(e => {

            setMessage(e.response.data.message)
            setDisasbled(false)
		    setIsError(true);
            toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

		});

	  }

      const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
				Filter: SelectColumnFilter,
				disableFilters: true
            },
			{
			Header: "Name",
			accessor: "name",
			disableSortBy: true,
			filter: 'equals',
			},
			{
			Header: "Product Description",
			accessor: "description",
            disableSortBy: true,
            disableFilters: true
			},
			{
			Header: "Price",
			accessor: "price",
            disableSortBy: true,
            disableFilters: true
			},
			{
			Header: "Units",
			accessor: "units",
			disableSortBy: true,
            disableSortBy: true,
            disableFilters: true

			},
			
			{
			Header: "Image",
			accessor: (values) => {

					let image = (<img src={values.image} width="150" height="150" />);
					// return totalYear > 0 ? totalYear : Math.floor(12 * (n - y))
					return image
				},
                disableSortBy: true,
				disableFilters: true
			},

		],
		[]
	  )

    return (
        <div>

        <div className="dashboard-container">

<Sidenav />
    <div className="dashboard-content-container" data-simplebar>
    <div className="dashboard-content-inner" >
        
        <div className="dashboard-headline">
            <h3><i className="icon-line-awesome-users"></i> Add Ecommerce Products</h3>

            <nav id="breadcrumbs" className="dark">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>Add Halls</li>
                </ul>
            </nav>
        </div>

        <div className="row">

            <div className="col-xl-12">
                <div className="dashboard-box margin-top-0">

                    <div className="headline">
                        <div>
                            <h3><i className="icon-feather-folder-plus"></i> Ecommerce Products</h3>
                            <div style={{ float: 'right' }}> <button className="btn btn-primary btn-sm" onClick={onOpenModal}> <i className="icon-feather-plus"></i> Add Products </button> </div>

                        </div>
                    </div>

                    <div className="previewProfilePic">
              </div>


              <Modal open={open} onClose={onCloseModal} center>
                               
                            <h2>Add Ecommerce Products </h2> <br />


							{ isSucess && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
										</div>)

						    }

                            { isError && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
										</div>) 

						    }

						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>



                        <form onSubmit={postProducts}>
                        <div className="row">

                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <h5>Product Name</h5>
                                    <input type="text" name="name"  value={name} onChange={e => {setName(e.target.value);}} className="with-border" required />
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <h5>Units</h5>
                                        <div className="input-with-icon">
                                            <input name="capacity" min="1" value={units} onChange={e => {setUnits(e.target.value);}} className="with-border" type="number" placeholder="unit"  required/>
                                        </div>
                                </div>
                            </div> 
                            
                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <h5>Price</h5>
                                        <div className="input-with-icon">
                                            <input name="price" min="1"  value={price} onChange={e => {setPrice(e.target.value);}} className="with-border" type="number" placeholder="price" required/>
                                        </div>
                                </div>
                            </div>

                            <div className="col-xl-12">
                                <div className="submit-field">
                                    <h5>Product Description</h5>
                                    <textarea cols="30" rows="5" name="description" value={description} onChange={e => {setDescription(e.target.value);}} className="with-border" required></textarea>
                                    <div className="uploadButton margin-top-30">
                                        <input className="uploadButton-input"  onChange={e => setImage(e.target.files[0])} name="image" type="file" accept="image/*" id="upload" />
                                        <label className="uploadButton-button ripple-effect" htmlFor="upload">Upload Files</label>
                                        <span className="uploadButton-file-name">Images or documents that might be helpful in describing the product</span>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="col-xl-12">
                                <button type="submit" className="btn btn-success btn-lg ripple-effect big margin-top-30" disabled={disabled}> <i className="icon-feather-plus"></i> Submit</button>
                            </div>
                        </form>


                    </Modal>





                            <div className="content with-padding padding-bottom-10">

                                                <Container style={{ marginTop: 80 }}>
                                                    <TableContainer
                                                        columns={columns}
                                                        data={products}
                                                    />
                                                </Container>
                                <br />

                            </div>
                </div>
            </div>




        </div>

        <DashboardFooter />

    </div>
</div>
        </div>
    </div>
    )
}
