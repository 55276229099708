import React from 'react'
import Footer from './footer';
import {Link} from 'react-router-dom';

export default function Contact(){

        return (
            <div>
            <section className="page-title centred">
                    <div className="pattern-layer"  style={{backgroundImage : `url('/assets/images/background/page-title.jpg')`}}></div>
                        <div className="auto-container">
                            <div className="content-box">
                                <h1>Contact</h1>
                                <ul className="bread-crumb clearfix">
                                <li><i className="flaticon-home-1"></i><Link to="/">Home</Link></li>
                                    <li>Contact</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                <section className="contact-section">
                <div className="auto-container">
                    <div className="contact-info">
                        <div className="row clearfix">
                            
                            <div className="info-block col-md-4 col-sm-6 col-xs-12">
                                <div className="inner-box">
                                    <div className="icon-box"><span className="fas fa-house-user"></span></div>
                                    <h4>VISIT</h4>

                                    <p>15 Kofo Abayomi Street, <br /> Victoria Island Lagos, Nigeria. </p>
                                </div>
                            </div>
                            
                            <div className="info-block col-md-4 col-sm-6 col-xs-12">
                                <div className="inner-box">
                                    <div className="icon-box"><span className="fas fa-phone-alt"></span></div>
                                    <h4>Call</h4>
                                    +234 809 310 7291
                                </div>
                            </div>
                            
                            <div className="info-block col-md-4 col-sm-6 col-xs-12">
                                <div className="inner-box">
                                    <div className="icon-box"><span className="fas fa-envelope"></span></div>
                                    <h4>Mail</h4>
                                    admin@metclub.ng
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="auto-container">
                    <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-1 big-column">
                        <div className="sec-title">
                            <h2>Get In Touch</h2>
                            <span className="separator" style={{backgroundImage : `url('/assets/images/icons/separator-1.png')`}}></span>
                        </div>
                        <div className="form-inner">
                            <form method="post" action="sendemail.php" id="contact-form" className="default-form">
                                <div className="row clearfix">
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="username" placeholder="Name *" required />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <input type="email" name="email" placeholder="Email *" required />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="phone" placeholder="Phone *" required />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <input type="text" name="subject" placeholder="Subject *" required />
                                    </div>
                                    
                                    <div className="column col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <textarea name="message" placeholder="Type Your Message"></textarea>
                                        </div>
                                    </div>
                                    
                                    <div className="column col-md-12 col-sm-12 col-xs-12 text-center">
                                        <div className="form-group">
                                            <button type="submit" className="theme-btn-two" name="submit-form">Submit Message<i className="flaticon-right-1"></i></button>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </section>
    
            <section id="de-map" className="no-top" aria-label="map-container">
                <div className="map-container map-fullwidth">
					<iframe title="address" src="https://maps.google.com/maps?q=15%20Kofo%20Abayomi%20St,%20Victoria%20Island%20106104,%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed" width="600" height="450" frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"> </iframe>
				</div>
            </section>
            <Footer />

            </div>
    )}
