import React, {useMemo, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import Sidenav from './sidenav'
import DashboardFooter from './dashboardfooter'
import Header from '../website/header'
import DataService from "./services/User";
import './bootstrap-grid.css';
import './dashboard.css';


export default function Dashboard() {
    const [user, setUser] = useState([]);

    const [metrics, setMetrics] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);

    useEffect(() => {
		
        const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
        }

		DataService.getUserMetrics(config)
		.then(result => {
			if(result.status== 200){
                setMetrics(result.data.data)
                setIsSuccess(true)
			}
			else {
				setIsError(true);
                setIsSuccess(false)
			  }
		}).catch(e => {
	
		  setIsError(true);
		});

        DataService.user(config)
        .then(
            res => {

            const response = res.data.data;
            
            setUser(response);

        }).catch(
            err => {
        });

}, []);

const greetCustomers = () => {

    var d = new Date();

    var time = d.getHours();

    if (time < 12) {

        return (<b> Good Morning </b>);
    }
    else if (time >= 12 && time <= 17) {

        return (<b> Good Afternoon </b>)
    }

    else if (time >= 17 && time <= 24) {

        return (<b> Good Evening </b>)
    }
}

const convertDateToString = (date) => 
{
  var result = new Date(date);

  return result.toLocaleDateString();
  
}

const renderHeader = () => {
    let headerElement = ['id','name','amount']

    return headerElement.map((key, index) => {
        return <th key={index}>{key.toUpperCase()}</th>
    })
}

const renderBody = () => {
    return metrics && metrics.totalSubscriptionPlans.map(({id, amount, name}) => {
        return <tr className="success" key={id}><td>{id}</td><td>{name}</td><td>{amount}</td></tr>
    })
}

const renderOrderHeader = () => {
    let headerElement = ['id','product','amount', 'status', 'date']

    return headerElement.map((key, index) => {
        return <th key={index}>{key.toUpperCase()}</th>
    })
}

const renderOrderBody = () => {
    let index = 1;
    return metrics && metrics.lastFiveOrder.map(({id, is_delivered, product, updated_at}) => {
        return <tr className="success" key={id}><td>{index++}</td><td>{product ? product.name : ''}</td><td>&#8358; {product ? product.price : ''}</td><td>{is_delivered ? (<small style={{ color:'black' }} >delivered</small>) : (<small style={{ color:'black' }}>pending</small>)}</td><td>{convertDateToString(updated_at)}</td></tr>
    })
}
      
return (
    <div>
        <section className="dashboard-container">
            <Sidenav />
            <div className="dashboard-content-container" data-simplebar>
                <div className="dashboard-content-inner">
                    <div className="dashboard-headline">
                        <h3> Hello, {greetCustomers()} <b>{user ? user.firstname : "Member!"} </b></h3>

                        <span>We are glad to see you again!</span>

                        <nav id="breadcrumbs" className="dark">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Dashboard</li>
                            </ul>
                        </nav>
                    </div>
                        <div className="single_element">
                            <div className="quick_activity">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="quick_activity_wrap">
                                            {
                                                user.is_admin == true ? ( <div className="single_quick_activity">
                                                                    <h4>Total Users</h4>
                                                                    <h3> <span className="counter"> { metrics && metrics.totalUsers ? metrics.totalUsers.length : 0 } </span> </h3>
                                                                </div> ) : ''

                                            }
                                        <div className="single_quick_activity" style={{ backgroudColor:"red !important"  }}>
                                            <h4>Total Hall Centers</h4>
                                            <h3> <span className=""> { metrics && metrics.totalHallCenters? metrics.totalHallCenters.length : 0 } </span> </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="single_element">
                            <div className="quick_activity">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="quick_activity_wrap">
                                            <div className="single_quick_activity">
                                                <h4>Balance</h4>
                                                <h3> &#8358; <span className=""> { metrics && metrics.totalBalance ? metrics.totalBalance : 0 } </span> </h3>
                                            </div>
                                            <div className="single_quick_activity">
                                                <h4>Total Month Transactions</h4>
                                                <h3> <span className=""> { metrics && metrics.totalTransaction ? metrics.totalTransaction.monthTransaction.length : 0 } </span> </h3>
                                            </div>
                                            <div className="single_quick_activity">
                                                <h4>Total Daily Transactions</h4>
                                                <h3> <span className=""> { metrics && metrics.totalTransaction ? metrics.totalTransaction.dailyTransaction.length : 0 } </span> </h3>
                                            </div>
                                            <div className="single_quick_activity">
                                                <h4>Total Orders</h4>
                                                <h3> <span className=""> { metrics && metrics.totalOrders ? metrics.totalOrders.length : 0} </span> </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <LineChart data={metrics && metrics.totalDeliveredOrder}/> */}

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="dashboard-box main-box-in-row">
                                    <div className="headline">
                                        <h3><i className="icon-feather-bar-chart-2"></i> Subscription Plans</h3>
                                        <div className="sort-by">

                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>{renderHeader()}</tr>
                                                    </thead>
                                                    <tbody>{renderBody()}</tbody>
                                                </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="dashboard-box main-box-in-row">
                                    <div className="headline">
                                        <h3><i className="icon-feather-bar-chart-2"></i> Last Five Orders</h3>
                                        <div className="sort-by">

                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="container">
                                                <table className="table">
                                                    <thead>
                                                        <tr>{renderOrderHeader()}</tr>
                                                    </thead>
                                                    <tbody>{renderOrderBody()}</tbody>
                                                </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <DashboardFooter />
                </div>
            </div>
        </section>
    </div> 
    )
}
