import React, {useState, useEffect}  from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Aboutus from "./components/website/aboutus";
import Services from "./components/website/services";
import Gallery from './components/website/gallery';
import Contact from "./components/website/contact";
import Index from "./components/website";

import Login from "./components/dashboard/login";
import Signup from "./components/dashboard/signup";
import Dashboard from "./components/dashboard/dashboard";
import Settings from "./components/dashboard/settings";
import Invoices from "./components/dashboard/invoices";
import EventBooking from "./components/dashboard/event_booking";
import Forgot from "./components/dashboard/forgot";
import Reset from "./components/dashboard/reset";
import Users from "./components/dashboard/users";
import EventsHalls from "./components/dashboard/events";
import AddEventCenter from "./components/dashboard/add_event_center";
import SendBroadcast from "./components/dashboard/send_broadcast";
import Calendar from "./components/dashboard/calendar";
import AddProducts from "./components/dashboard/add_products";
import AllHallOrders from "./components/dashboard/all_hall_orders";
import SingleUser from "./components/dashboard/single_user";
import President_club from "./components/website/president_club";
import Club_Trustees from "./components/website/club_trustees";
import ManagementCommittee from "./components/website/managementcommittee";
import AddPresident from "./components/dashboard/add_president";
import Trustee from "./components/dashboard/trustees";
import Committee from "./components/dashboard/committee";
import AllEcommerceOrders from "./components/dashboard/all_ecommerce_orders";
import Notfound from "./components/notfound";
import PrivateRoute from './PrivateRoute';
import Header from './components/website/header';
import DataService from './components/dashboard/services/User';
import Statement from "./components/dashboard/statement";
import EcommerceOrders from "./components/dashboard/ecommerce_orders";
import MemberSpending from "./components/dashboard/members_spending";
import MyPlacedOrders from "./components/dashboard/my_placed_order";
import Membership from "./components/website/membership";
import EcommerceProduct from "./components/dashboard/ecommerce_product";
import Cart from "./components/dashboard/cart";
import Checkout from "./components/dashboard/checkout";
import Holiday from "./components/dashboard/holiday";


export default function App() {

  const [authTokens, setAuthTokens] = useState();
  
  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  const [user, setUser]= useState('');

  useEffect(() => {

     const config = {
          headers: {
              Authorization : 'Bearer '+ localStorage.getItem('token')
          }
      }

      DataService.user(config).then(
          res => {

          const response = res.data.data;
          
          setUser(response);

        }).catch(
          err => {
              // console.log(err)
          }
      )
    }, []);

  return (
    <div>
      <Router>
      <Header user={user}/>

      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="/aboutus" component={Aboutus} />  
        <Route path="/membership" component={Membership} />  
        <Route path="/services" component={Services} /> 
        <Route path="/gallery" component={Gallery} />  
        <Route path="/contact" component={Contact} /> 
        <Route path="/president_club" component={President_club} /> 
        <Route path="/trustees" component={Club_Trustees} /> 
        <Route path="/committee" component={ManagementCommittee} /> 

        {/* <Route path="/dashboard" component={Dashboard} />  */}
        {/* <PrivateRoute path="/dashboard" component={ () => <Dashboard user={user} />} /> */}
        <PrivateRoute path="/dashboard" component={ Dashboard} />

        <PrivateRoute path="/settings" component={Settings} /> 
        <Route path="/login" component={Login} /> 
        <Route path="/signup" component={Signup} /> 
        <PrivateRoute path="/invoice" component={Invoices} /> 
        <PrivateRoute path="/ecommerce" component={EcommerceProduct} /> 
        <PrivateRoute path="/holiday" component={Holiday} /> 
        <PrivateRoute path="/event_booking" component={EventBooking} /> 
        <Route path="/forgot" component={Forgot} />
        <Route path="/reset/:id" component={Reset} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/single_user/:id" component={SingleUser} />
        <PrivateRoute path="/events_centers" component={EventsHalls} />
        <PrivateRoute path="/add_halls" component={AddEventCenter} />
        <PrivateRoute path="/add_president" component={AddPresident} />
        <PrivateRoute path="/add_trustee" component={Trustee} />
        <PrivateRoute path="/add_committee" component={Committee} />
        <PrivateRoute path="/add_products" component={AddProducts} />
        <PrivateRoute path="/all_ecommerce_orders" component={AllEcommerceOrders} />
        <PrivateRoute path="/my_placed_orders" component={MyPlacedOrders} />
        <PrivateRoute path="/all_hall_orders" component={AllHallOrders} />
        <PrivateRoute path="/send_broadcast" component={SendBroadcast} />
        <PrivateRoute path="/calendar" component={Calendar} />
        <PrivateRoute path="/statement" component={Statement} />
        <PrivateRoute path="/all_orders" component={EcommerceOrders} />
        <PrivateRoute path="/all_spending" component={MemberSpending} />
        <PrivateRoute path="/cart" component={Cart} />
        <PrivateRoute path="/checkout" component={Checkout} />

        <Route path="*" component={Notfound} />

      </Switch>
      </Router>

    </div>
  );
}
