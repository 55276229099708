import React, { useState, useEffect } from 'react'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import Dataservice from './services/User'

export default function Sidenav(props) {

    const [isError, setIsError] = useState(false);

	const [currentUser, setCurrentUser]= useState('');

    let history = useHistory();

    //assigning location variable
    const location = useLocation();

    const { pathname } = location;

    const splitLocation = pathname.split("/");

    const logOut = () => {

        localStorage.clear();
    
       return history.push('/login');
    }

    useEffect(() => {
        Dataservice.getCurrentUser()
        .then(result => {
    
            if(result.status === 200){
                setCurrentUser(result.data.data)
            }
            else {
    
                setIsError(true);
              }
        }).catch(e => {
    
          setIsError(true);
        });      }, [])



    return (
            <div className="page-container dashboard-sidebar" style={{ background:"#495057" }}>
                <div className="dashboard-sidebar-inner" data-simplebar>
                    <div className="dashboard-nav-container">

                        <a href="#" className="dashboard-responsive-nav-trigger">
                            <span className="hamburger hamburger--collapse" >
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </span>
                            <span className="trigger-title">Dashboard Navigation</span>
                        </a>

                    <div className="dashboard-nav">
                        <div className="dashboard-nav-inner" style={{marginTop: "20px"}}>
                            <br />
                            <ul data-submenu-title="Start">
                                <li className={splitLocation[1] === "dashboard" ? "active" : ""}><Link to="/dashboard"><i className="icon-material-outline-dashboard"></i> Dashboard</Link></li>
                            </ul>
                            <ul data-submenu-title="Profile Organize and Manage">
                                <li className={splitLocation[1] === "ecommerce" ? "active" : ""}><Link to="/ecommerce"><i className="icon-material-outline-shopping-cart"></i> Ecommerce</Link></li>
                                {currentUser.is_admin ? (<li className={splitLocation[1] === "add_products" ? "active" : ""}><Link to="/add_products"><i className="icon-line-awesome-money"></i> Add Ecommerce Product</Link></li> ) : '' }
                                <li className={splitLocation[1] === "events_centers" ? "active" : ""} ><Link to="/events_centers"><i className="icon-material-outline-home"></i> Events Centers</Link></li>
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "add_halls" ? "active" : ""} ><Link to="/add_halls"><i className="icon-material-outline-home"></i> Add Event Centers</Link></li> ) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "calendar" ? "active" : ""} ><Link to="/calendar"><i className="icon-line-awesome-calendar"></i> Calendar</Link></li> ) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "holiday" ? "active" : ""} ><Link to="/holiday"><i className="icon-material-outline-home"></i> Holidays </Link></li> ) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "all_ecommerce_orders" ? "active" : ""} ><Link to="/all_ecommerce_orders"><i className="icon-material-outline-restaurant"></i> All Ecommerce Orders</Link></li> ) : '' }
                                { <li className={splitLocation[1] === "my_placed_orders" ? "active" : ""} ><Link to="/my_placed_orders"><i className="icon-material-outline-restaurant"></i> My Placed Orders</Link></li> }  {/* icon-material-outline-assignment */}
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "all_hall_orders" ? "active" : ""} ><Link to="/all_hall_orders"><i className="icon-material-outline-home"></i> All Hall Orders</Link></li>) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "add_president" ? "active" : ""} ><Link to="/add_president"><i className="icon-material-outline-account-circle"></i> Add President</Link></li>) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "add_trustee" ? "active" : ""} ><Link to="/add_trustee"><i className="icon-material-outline-account-circle"></i> Add Trustees</Link></li>) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "add_committee" ? "active" : ""} ><Link to="/add_committee"><i className="icon-material-outline-account-circle"></i> Add Committee</Link></li>) : '' }
                                <li className={splitLocation[1] === "statement" ? "active" : ""} ><Link to="/statement"><i className="icon-material-outline-home"></i> Statement </Link></li>
                            </ul>

                                {currentUser.is_admin ? (
                                        <ul data-submenu-title="Account">
                                            <li className={splitLocation[1] === "all_orders" ? "active" : ""} ><Link to="/all_orders"><i className="icon-material-outline-home"></i> Ecommerce Orders</Link></li> 
                                            <li className={splitLocation[1] === "all_spending" ? "active" : ""} ><Link to="/all_spending"><i className="icon-material-outline-home"></i> Yearly Spending</Link></li> 
                                        </ul>
                                ) : '' }

                            <ul data-submenu-title="Settings">
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "signup" ? "active" : ""} ><Link to="/signup"><i className="icon-material-outline-account-circle"></i> Add Account</Link></li>) : '' }
                                {currentUser.is_admin ? ( <li className={splitLocation[1] === "users" ? "active" : ""} ><Link to="/users"><i className="icon-line-awesome-group"></i> Members</Link></li>) : '' }
                                {/* {currentUser.is_admin ? ( <li className={splitLocation[1] === "send_broadcast" ? "active" : ""} ><Link to="/send_broadcast"><i className="icon-material-outline-email"></i> Send Broadcast Messages</Link></li>) : '' } */}
                                <li className={splitLocation[1] === "settings" ? "active" : ""} ><Link to="/settings"><i className="icon-material-outline-settings"></i> Settings</Link></li>
                                <li><Link onClick={() => logOut()} to={'/login'}><i className="icon-material-outline-power-settings-new"></i> Logout</Link></li>
                            </ul>
                        </div>
                    </div>

                    
                    </div>
                </div>
	        </div>
    )
}
