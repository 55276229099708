import React, {useEffect} from 'react'
import './app.css';
// import './bootstrap-grid.css';

export default function Invoices() {
    
    useEffect(() => {
           
    }, []);
    
    return (
        <div>
            
<div className="bodyinvoice">
            <div className="print-button-container">
	<a href="javascript:window.print()" className="print-button">Print this invoice</a>
</div>

{/* <!-- Invoice --> */}
<div id="invoice">

	{/* <!-- Header --> */}
	<div className="row">
		<div className="col-xl-6">
			<div id="logo"><img src="assets/images/HandiworkLogo1.png" alt="" /></div>
		</div>

		<div className="col-xl-6">	

			<p id="details">
				<p className="strongb">Order:</p> #00124 <br />
				<p className="strongb">Issued:</p> 20/08/2021 <br />
				{/* Due 7 days from date of issue */}
			</p>
		</div>
	</div>


	{/* <!-- Client & Supplier --> */}
	<div className="row">
		<div className="col-xl-12">
			<h2>Invoice</h2>
		</div>

		<div className="col-xl-6">	
			<p className="margin-bottom-5 strongb">Supplier</p>
			<p>
				Seyi De Assistance Ltd. <br />
				21 Ikosi Ketu <br />
				Lagos, Nigeria <br />
			</p>
		</div>

		<div className="col-xl-6">	
			<p className="margin-bottom-5 strongb">Customer</p>
			<p>
				Ayo Ayo <br />
				21 Ikosi Ketu <br />
				Lagos, Nigeria <br />
			</p>
		</div>
	</div>


	{/* <!-- Invoice --> */}
	<div className="row">
		<div className="col-xl-12">
			<table className="margin-top-20">
				<tr>
					<th>Description</th>
					<th>Price</th>
					<th>VAT (20%)</th>
					<th>Total</th>
				</tr>

				<tr>
					<td>Tiling</td> 
					<td>&#8358; 49.00</td>
					<td>&#8358; 9.80</td>
					<td>&#8358; 58.80</td>
				</tr>
			</table>
		</div>
		
		<div className="col-xl-4 col-xl-offset-8">	
			<table id="totals">
				<tr>
					<th>Total Due</th> 
					<th><span>&#8358; 58.80</span></th>
				</tr>
			</table>
		</div>
	</div>


	{/* <!-- Footer --> */}
	<div className="row">
		<div className="col-xl-12">
			<ul id="footerinvoice">
				<li><span>www.example.com</span></li>
				<li>office@example.com</li>
				<li>+(234) 123-456</li>
			</ul>
		</div>
	</div>
		
</div>
</div>

        </div>
    )
}
