import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import DataService from "../dashboard/services/Service";

function Header(props) {
  let history = useHistory();

  //assigning location variable
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [cart, setCart] = useState("");
  const [isError, setIsError] = useState("");

  const logOut = () => {
    localStorage.clear();

    return history.push("/login");
  };
  // useEffect(() => {
  // console.warn("reload")
  //   return () => {

  //   }
  // }, [])

  useEffect(() => {
    console.warn("reload")
    getCart();
  }, []);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getCart = () => {
    DataService.getCart(config)
      .then((result) => {
        if (result.status === 200) {
          setCart(result.data.data);
        } else {
          setIsError(true);
        }
      })
      .catch((e) => {
        setIsError(true);
      });
  };

  const users = props.user;

  let loginButtons;

  if (users === "" || users === undefined) {
    loginButtons = (
      <div>
        <li>
          <Link to="/login">
            <button className="theme-btn-two"> Login </button>
          </Link>
        </li>
      </div>
    );
  } else {
    loginButtons = (
      <div>
        <div className="">
          <li className="dropdown">
            <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
              <div className="user-avatar status-online">
                <img
                  src={
                    users.image
                      ? users.image
                      : "/assets/images/user-avatar-placeholder.jpeg"
                  }
                  alt=""
                />
              </div>
            </Link>
            <ul className="dropdown-menu user-menu-small-nav listStyleHeader">
              <li>
                {" "}
                <small style={{ color: "black" }}>
                  {" "}
                  {users.firstname} <span>{users.member_number}</span>{" "}
                </small>{" "}
              </li>{" "}
              <br />
              <li className={splitLocation[1] === "dashboard" ? "active" : ""}>
                <Link to={"/dashboard"}>
                  <i className="icon-material-outline-dashboard"></i>{" "}
                  <small> Dashboard </small>{" "}
                </Link>
              </li>
              <li className={splitLocation[1] === "settings" ? "active" : ""}>
                <Link to="/settings">
                  <i className="icon-material-outline-settings"></i>{" "}
                  <small> Settings </small>
                </Link>
              </li>
              <li>
                {" "}
                <Link onClick={() => logOut()} to={"/login"}>
                  <i className="icon-material-outline-power-settings-new"></i>{" "}
                  <small> Logout </small>
                </Link>{" "}
              </li>
            </ul>
          </li>
          <li className="shop-cart">
            <Link to={"/cart"}>
              <i className="flaticon-shopping-cart-1"></i>
              <span>{cart.length}</span>
            </Link>
          </li>
        </div>
      </div>
    );
  }
  return (
    <div>
      <header className="main-header">
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box">
              <figure className="logo-box">
                {/* <a href="index.html"><img src="assets/images/logo.png" alt="" /></a> */}
                <Link to={"/"}>
                  <img
                    className="img-fluid"
                    src="/assets/images/metro-logo-small.png"
                    alt=""
                  />
                </Link>
              </figure>
              <div className="menu-area">
                {/* <!--Mobile Navigation Toggler--> */}
                <div className="mobile-nav-toggler">
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                  <i className="icon-bar"></i>
                </div>
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix listStyleHeader">
                      <li
                        className={
                          splitLocation[1] === "" ? "active current" : ""
                        }
                      >
                        <Link to="/"> Home </Link>
                      </li>
                      <li className="dropdown">
                        <Link to={"/aboutus"}>About us</Link>
                        <ul className="listStyleHeader">
                          <li
                            className={
                              splitLocation[1] === "aboutus"
                                ? "active current"
                                : ""
                            }
                          >
                            <Link to={"/aboutus"}>About us</Link>
                          </li>
                          <li
                            className={
                              splitLocation[1] === "president_club"
                                ? "active current"
                                : ""
                            }
                          >
                            <Link to={"/president_club"}>Club President</Link>
                          </li>
                          <li
                            className={
                              splitLocation[1] === "trustees"
                                ? "active current"
                                : ""
                            }
                          >
                            <Link to={"/trustees"}>Club Trustees</Link>
                          </li>
                          <li
                            className={
                              splitLocation[1] === "committee"
                                ? "active current"
                                : ""
                            }
                          >
                            <Link to={"/committee"}>Management Committee</Link>
                          </li>
                        </ul>
                      </li>
                      <li
                        className={
                          splitLocation[1] === "membership"
                            ? "active current"
                            : ""
                        }
                      >
                        <Link to="membership">Membership</Link>
                      </li>
                      <li
                        className={
                          splitLocation[1] === "services"
                            ? "active current"
                            : ""
                        }
                      >
                        <Link to="services">Services</Link>
                      </li>
                      <li
                        className={
                          splitLocation[1] === "gallery" ? "active current" : ""
                        }
                      >
                        <Link to="gallery">Gallery</Link>
                      </li>
                      <li
                        className={
                          splitLocation[1] === "contact" ? "active current" : ""
                        }
                      >
                        <Link to="contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <ul className="menu-right-content clearfix">{loginButtons}</ul>
            </div>
          </div>
        </div>

        {/* <!--sticky Header--> */}
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <Link to={"/"}>
                    <img
                      className="img-fluid"
                      src="/assets/images/metro-logo-small.png"
                      alt=""
                    />
                  </Link>
                </figure>
              </div>
              <div className="menu-area pull-right">
                <nav className="main-menu clearfix">
                  {/* <!--Keep This Empty / Menu will come through Javascript--> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Mobile Menu  --> */}
      <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn">
          <i className="fas fa-times"></i>
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <Link to={"/"}>
              <img
                className="img-fluid"
                src="/assets/images/metro-logo-small.png"
                alt=""
              />
            </Link>
          </div>
          <div className="menu-outer">
            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>15 Kofo Abayomi Street, Victoria Island Lagos, Nigeria</li>
              <li>
                <a a href="tel:+2348093107291">
                  {" "}
                  +234 809 310 7291
                </a>
              </li>
              <li>
                <a href="mailto:admin@metclub.ng">admin@metclub.ng</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* <!-- End Mobile Menu --> */}
    </div>
  );
}

export default Header;
