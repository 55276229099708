import React from 'react'
import Footer from './footer';
import {Link} from 'react-router-dom';

import './gallery.css';

export default function Gallery() {

    const loopNewImages =() => {

        let createCustomArray = new Array(15).fill('dummyData');

        var count = 0;

        return createCustomArray && createCustomArray.map((data, index) => {
            var imageCount = count+=1;
            return (
                    <div  key={index++}>
                        <div className="card">
                            <div className="card-image">
                                <a href={`/assets/images/gallery/${imageCount}.jpg`} rel="ligthbox" data-fancybox="gallery" data-caption="Caption Images 1">
                                    <img  src={`/assets/images/gallery/${imageCount}.jpg`} className="zoom img-fluid " alt="gallery" />
                                </a>
                            </div>
                        </div>
                    </div>
            )
        })
    }

    const loopOldImages =() => {

        let createCustomArray = new Array(15).fill('dummyData');

        var count = 0;

        return createCustomArray && createCustomArray.map((data, index) => {
            var oldImageCount = count+=1;

            return (
                    <div  key={index++}>
                        <div className="card">
                            <div className="card-image">
                                <a href={`/assets/images/gallery/${oldImageCount}.jpg`} className="fancybox" rel="ligthbox" data-fancybox="gallery" data-caption="Caption Images 1">
                                    <img  src={`/assets/images/gallery/${oldImageCount}.jpg`} className="zoom img-fluid " alt="gallery" />
                                </a>
                            </div>
                        </div>
                    </div>
            )
        })
    }

  return (
            <div>
                <section className="page-title centred">
                    <div className="pattern-layer"  style={{backgroundImage : `url('/assets/images/background/page-title.jpg')`}}></div>
                        <div className="auto-container">
                            <div className="content-box">
                                <h1>Gallery</h1>
                                <ul className="bread-crumb clearfix">
                                <li><i className="flaticon-home-1"></i><Link to="/">Home</Link></li>
                                    <li>Gallery</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <br />
                <main className="mainimage">
                    <div className="container imageContainer">
                        {loopNewImages()}
                        {loopOldImages()}
                    </div>
                </main>
                <Footer />
            </div>
  )
}
