import React, {useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../website/footer';
import Header from '../website/header';
import DataService from "./services/Service";
import { Card, Logo, Form, Success, Input, Button, Error } from "../../components/dashboard/AuthForms";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { render } from 'react-dom';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

export default function Login()
{
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState('');
	const [isSucess, setIsSucess] = useState(false);
	const [disabled, setDisasbled] = useState(false)

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");


	const postLogin = (e) => {
		e.preventDefault();
		var data = {
			login,
			password
		}

		setDisasbled(true);

		DataService.login(data)
		.then(result => {
			if (result.status === 200) {
	
				localStorage.setItem("token", result.data.data)

				setMessage(result.data.message)

				setIsSucess(true);
	
				setDisasbled(false)
	
				setLoggedIn(true);
				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

			  } else {
				setMessage(result.data.message)

				setIsError(true);
	
				setDisasbled(false)
				toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

	
			  }
			}).catch(e => {
				setMessage(e.response.data.message)

				setDisasbled(false)
	
				setIsError(true);
				toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

			}); 
	
		  }
		
		  if (isLoggedIn) {
	
			return <Redirect to="/dashboard" />;
		}
	


    return (
        <div>
            <div id="titlebar" className="gradient " >
	<div className="container" >
		<div className="row">
			<div className="col-md-12">

				<h2>Log In</h2>

				{/* <!-- Breadcrumbs --> */}
				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li>Log In</li>
					</ul>
				</nav>

			</div>
		</div>
	</div>
</div>



<div className="container">

	<div className="row">
		<div className="col-xl-5 offset-xl-3" >
			<div className="login-register-page" >
				
				<div className="welcome-text">
					<h3>We're glad to see you again!</h3>

				</div>

				{ isSucess && (<div>
					<ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />							</div>)

				}

				{ isError && (<div>
					<ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />							</div>)

				}

				<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
				</LoadingOverlay>

					
				<form onSubmit={postLogin}>
					<div className="input-with-icon-left">
						<i className="icon-material-baseline-mail-outline"></i>
						<input 
							type="text" 
							className="input-text with-border" 
							name="login" 
							value={login}
							onChange={e => {
								setLogin(e.target.value);
							  }}
							placeholder="Enter Member Number or Email Address" 
							required
						/>
					</div>

					<div className="input-with-icon-left">
						<i className="icon-material-outline-lock"></i>
						<input 
							type="password" 
							className="input-text with-border" 
							name="password" 
							value={password}
							onChange={e => {
								setPassword(e.target.value);
							}}
							placeholder="Password" 
							required
						/>
					</div>
					<Link to="/forgot" className="forgot-password">Forgot Password?</Link>
					
					<button className="button full-width theme-btn-two" type="submit" disabled={disabled} >Log In <i className="flaticon-right-1"></i> </button>

				</form>
			</div>
		</div>
	</div>
</div>
<div className="margin-top-70"></div>

<Footer />
        </div>
    )
}