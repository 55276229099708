import React, {useMemo, useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header';
import DashboardFooter from './dashboardfooter';
import Sidenav from './sidenav';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "./services/Service";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";

import {
	Container,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import TablePrintContainer from "./tableprintcontainer";
import { SelectColumnFilter } from './filters';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import 'material-react-toastify/dist/ReactToastify.css';


export default function EcommerceOrders() {
    const [orders, setOrders] = useState([]);

	const [message, setMessage] = useState(false);

	const [isError, setIsError] = useState(false);

	const [isSucess, setIsSucess] = useState(false);

    const [disabled, setDisasbled] = useState(false)


	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
 


	useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    

		DataService.confirmedOrder()
		.then(result => {
			if (result.status === 200) {
				setOrders(result.data.data);

			} else {
				setIsError(true);
			}
			}).catch(e => {
			setIsError(true);
			});

	  
      }, []);

		const columns = useMemo(
			() => [
                {
                    Header: "Member Number",
                    accessor: "user.member_number",
                    disableSortBy: true,
                    filter: 'equals',
                },
                {
                    Header: "Name",
                    accessor: (values) => {
                        return values.user.firstname + ' '+ values.user.lastname;
                        }
                },

				{
                    Header: "Email",
                    accessor: "user.email",
                    disableSortBy: true,
                    filter: 'equals',
				// 	disableFilters: true
				},
				{
                    Header: "Phone",
                    accessor: "user.phone",
                    disableSortBy: true,
                    filter: 'equals'
				},
				{
                    Header: "Product",
                    accessor: "product.name",
                    filter: 'equals',
                    Filter: SelectColumnFilter,
				},
                {
                    Header: "Price",
                    accessor: "product.price",
                    filter: 'equals',
                    Filter: false,
                },
				// {
				// Header: "User Address",
				// accessor: (values) => {

				// 	let result = (<div style={{ color:"black" }}> <h5> Home Address : {values.user.home_address} </h5> <br />  <h5> Office Address : {values.user.office_address} </h5>  </div>)

				// 	return result
				// 	},
				// 	disableSortBy: true,
				// 	disableFilters: true
				// },

				{
					Header: "Date",
					accessor: (values) => {
		
						return new Date(values.updated_at).toLocaleDateString();
					},
						disableSortBy: true,
						disableFilters: true
					},
				// {
				// Header: "Approve Users",
				// accessor: (values) => {

				// 	return values.is_delivered == true ? (<button className='btn btn-success btn-sm' >Delivered</button>) : (<button className='btn btn-primary btn-sm' onClick={() => deliver(values.id)} disabled={disabled}>Complete Order</button>)
				// },
				// disableSortBy: true,
				// disableFilters: true
				// }
			],
		)
    return (
        <div>
            <div className="dashboard-container">

			<Sidenav />
           
		    <div className="dashboard-content-container" data-simplebar>
			<div className="dashboard-content-inner" >
			

			<div className="dashboard-headline">
				<h3>All Ecommerce Orders</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Orders</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">

				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-line-awesome-group"></i> All Ecommerce Orders</h3>
						</div>

						<div className="content with-padding padding-bottom-0">
							
						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>

						<Container style={{ marginTop: 100 }}>
							<TablePrintContainer
								columns={columns}
								data={orders}
							/>
							
							{orders.length == 0 ? (<div align="center"> <img src="/assets/images/no_data1.gif" /> </div>) : ""}

						</Container>



						</div>
					</div>
				</div>

						
				
				
				
                </div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}