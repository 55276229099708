import React, {useEffect, useState} from 'react'
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import { Link, Redirect } from 'react-router-dom';
import DataService from "./services/Events";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";

export default function Events() {

    const [halls, setHalls] = useState([]);

	const [quantity, setQuantity] = useState(1);
	const [address, setAddress] = useState('');
	const [hall_center_id, setHallCenterId] = useState('');
	const [message, setMessage] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
 
    useEffect(() => {
        
        getHalls();

      }, []);

      const getHalls =() => {
        DataService.halls()
		.then(result => {
			if (result.status === 200) {

				setHalls(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }
      
      const confirmHalls = (id) => {

		var data = {
			quantity,
            address,
			hall_center_id:id
		}
		
		const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
		}

		setDisasbled(true);

		DataService.OrderHall(data, config)
		.then(result => {
			if (result.status === 200) {

				setMessage(result.data.message)

				setIsSucess(true);
	 
				setIsError(false);
	 
				setDisasbled(false)
				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

			   } else {
	 
				 setMessage(result.data.message)

				 setIsError(true);
	 
				 setDisasbled(false)
				 toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

	 
			   }
			 }).catch(e => {
	 
				 setMessage(e.response.data.message)

			   setIsError(true);
	 
			   setDisasbled(false)
			   toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

	 
			 });
		   }

     const hallList = () => {
        return halls && halls.map(({ id, name, picture_url, price, capacity, is_available, description }) => {
            return (
                    // <article key={id}>
                    //     <img src={picture_url}  style={{ height:"300px", width:"450px" }} alt="halls photo" />
                    //     <div className="text">
                    //         <h3>{name}</h3>
                    //         <br />
                    //         <div  style={{ float:"left" }}>
                    //          price: <button  className="btn btn-danger"> &#8358; {price}</button>

                    //         </div>
					// 		{capacity == undefined  ? '' : ( <div><i className='fas fa-head-side-mask' style={{ color: 'red' }} ></i> <i className="fas fa-virus-slash" style={{ color: 'green' }} ></i> </div>)}
                    //         <h3>Capaicity : {capacity != undefined ? Math.ceil(capacity / 2) : ''  }</h3> 

                    //         <br />
                    //         <h6>{description}</h6> 
                    //         <br />

					// 		<button onClick={() => confirmHalls(id)} className="btn btn-primary btn-block" disabled={disabled}>Book Now</button>

                    //     </div>
					// </article>

					<div className="col-lg-3 col-md-6 col-sm-12 shop-block" key={id}>
					<div className="shop-block-one">
						<div className="inner-box">
							<figure className="image-box">
								<img src={picture_url} style={{ height:"270px", width:"320px" }} className="img-fluid" alt="hall" />
								<ul className="info-list clearfix">
									{/* <li><a href="index.html"><i className="flaticon-heart"></i></a></li> */}
									<li></li>
									<li> <a onClick={() => confirmHalls(id)} disabled={disabled}> Book Now </a></li>
									{/* <li><a href={picture_url} className="lightbox-image" data-fancybox="gallery"><i className="flaticon-search"></i></a></li> */}
								</ul>
							</figure>
							<div className="lower-content">
								<a href="#">{name}</a>
								<p>Capaicity : {capacity}</p>
								<span className="price">&#8358; {price}</span>
							</div>
						</div>
					</div>
					</div>
				)
        })
    } 
      return (
        <div>
            <div className="dashboard-container">
				<Sidenav />
				<div className="dashboard-content-container" data-simplebar>
					<div className="dashboard-content-inner" >
						<div className="dashboard-headline">
							<h3>Hall Booking</h3>
							<nav id="breadcrumbs" className="dark">
								<ul>
									<li><a href="#">Home</a></li>
									<li><a href="#">Dashboard</a></li>
									<li>Hall</li>
								</ul>
							</nav>
						</div>
						
						<div className="row">
							<div className="col-xl-12">
								<div className="dashboard-box margin-top-0">
									<div className="headline">
										<h3><i className="icon-material-outline-shopping-cart"></i> Hall</h3>
									</div>
										{ isSucess && (<div>
											<ToastContainer
												position="top-right"
												autoClose={5000}
												hideProgressBar={false}
												newestOnTop={false}
												closeOnClick
												rtl={false}
												pauseOnFocusLoss
												draggable
												pauseOnHover
											/>
											</div>)
										}

										{ isError && (<div>
											<ToastContainer
												position="top-right"
												autoClose={5000}
												hideProgressBar={false}
												newestOnTop={false}
												closeOnClick
												rtl={false}
												pauseOnFocusLoss
												draggable
												pauseOnHover
											/>
											</div>)
										}

									<LoadingOverlay
										active={disabled}
										spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
										text='Loading...'
									>
									</LoadingOverlay>

									<section className="shop-page-section shop-page-1">
										<div className="auto-container">
											<div className="our-shop">
												<div className="row clearfix">
												{hallList()}
													
												</div>
											</div>
											{/* paginate */}
										</div>
									</section>
									
								</div>
							</div>
						</div>
						<DashboardFooter />
					</div>
				</div>
			</div>
    </div>
    )
}