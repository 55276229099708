import React, { Component } from 'react'
import Footer from './footer'
import BarAndKitchen from './bar_and_kitchen_slider';
import LibrarySlider from './library_slider';
import { Link } from 'react-router-dom';

export default function Services (){
    return (
        <div>
            <section className="page-title centred">
            <div className="pattern-layer"  style={{backgroundImage : `url('/assets/images/background/page-title.jpg')`}}></div>
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Our Services</h1>
                        <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><Link to="/">Home</Link></li>
                            <li>Our Services</li>
                        </ul>
                    </div>
                </div>
            </section>
            
            <section className="history-section" >
                <div className="auto-container">
                    <div className="sec-title">
                        <h2>BAR & KITCHEN</h2>
                        <div className="separator"></div>
                        <div className="text">BAR & KITCHEN</div>
                    </div>
                
                
                    <div className="row clearfix">
                        <div className="video-column col-md-6 col-sm-12 col-xs-12">
                            <div className="video-box">
                            
                                <figure className="image">
                                <BarAndKitchen />
                                </figure>
                            </div>
                        </div>

                        <div className="content-column col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-box">
                                <br />
                                <p>
                                    The Club has a kitchen that serves hot meals, assorted canapés and sandwiches from 9am to 7pm.
                                    Breakfast is served between 8.am and 11am in the Family Restaurant.<br /> 
                                    The swimming pool bar is also available to serve members and their guest who wish to relax 
                                    by the poolside and the Members Lounge Bar is available to members who prefer the lounge area within the premises.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{background:'#F8F8F8'}}>
                <div className="auto-container">
                    <div className="sec-title">
                        <h2>SQUASH</h2>
                        <div className="separator"></div>
                        <div className="text">SQUASH</div>
                    </div>
                    
                    <div className="row clearfix">
                        <div className="video-column col-md-6 col-sm-12 col-xs-12">
                            <div className="video-box">
                                <figure className="image">
                                    <img src="/assets/images/services/squash.jpg" alt="" />
                                </figure>
                            </div>
                        </div>

                        <div className="content-column col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-box">
                            <br />

                                <p>This is an indoor game that can be played irrespective of the time, day or night by two or more players in a rectangular court. 
                                    The metropolitan Club’s Squash court is located directly in front of the swimming pool.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section>
                <div className="auto-container">

                    <div className="sec-title">
                        <h2>THE LIBRARY</h2>
                        <div className="separator"></div>
                        <div className="text">THE LIBRARY</div>
                    </div>
                    
                    <div className="row clearfix">

                        <div className="video-column col-md-6 col-sm-12 col-xs-12">
                            <div className="video-box">
                                <figure className="image">
                                    <LibrarySlider />
                                </figure>
                            </div>
                        </div>

                        <div className="content-column col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-box">
                            <br />

                                <p>
                                    The Club’s library has a stock of reference books, local and foreign magazine/ newspaper, fictions, 
                                    books on sports and general literature and current affairs. The library is opened every day from 9am to 7pm. 
                                    It also opens on public holidays.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{background:'#F8F8F8'}}>
                <div className="auto-container">
                    <div className="sec-title">
                        <h2>SWIMMING POOL</h2>
                        <div className="separator"></div>
                        <div className="text">
                            SWIMMING POOL
                        </div>
                    </div>

                    <div className="row clearfix">

                        <div className="video-column col-md-6 col-sm-12 col-xs-12">
                            <div className="video-box">
                                <figure className="image">
                                    <img src="/assets/images/services/swimmingpool.JPG" alt="" />
                                </figure>
                            </div>
                        </div>

                        <div className="content-column col-md-6 col-sm-12 col-xs-12">
                            <div className="inner-box">
                                <br />
                                <p>
                                    As swimming is a sport that is highly recommended by medical experts for therapy and because the Metropolitan Club takes the health of its members serious. 
                                    The Club has a standard size clean, enticing swimming pool with soothing water, adjustable sun loungers’ bed/chairs and tables around the pool. 
                                    Two cloak rooms for ladies and gents and also available at all times are trained lifeguards.
                                </p> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
    </div>
)}
