import React, {useEffect, useState} from 'react'
import DataService from "../dashboard/services/Service";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export default function Checkout() {

    const [cart, setCart] = useState([]);
    const [isError, setIsError] = useState('');
    const [address, setAddress] = useState('');
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
	const [message, setMessage] = useState('');


    const config = {
        headers: {
            Authorization : 'Bearer '+ localStorage.getItem('token')
        }
    }
    useEffect(() => {
		getCart();
      }, []);

      const getCart = () => {
        DataService.getCart(config)
		.then(result => {
			if (result.status === 200) {
				setCart(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }                    

    const cartList = () => {
        return cart && cart.map(({ id, amount, product }) => {
            return (
                    <li key={id}>
                        <div className="single-box clearfix">
                            <img src={product.image} style={{ height:"80px", width:"90px" }} alt="" />
                            <h6>{product.name}</h6>
                            <span>&#8358; {amount}</span>
                        </div>
                    </li>
			)
        })
    }

    const cartTotalAmount = () => {
        let amount = 0;

        cart && cart.forEach(element => {
            amount += element.amount
        });

        return amount;
    } 

    const checkout = (e) => {
		e.preventDefault();
		var data = 
        {
            cart,
            address,
            quantity : 1
        }

		setDisasbled(true);

		DataService.OrderItem(data, config)
		.then(result => {
			if (result.status === 200) {

				setMessage(result.data.message)

				setIsSucess(true);
	
				setDisasbled(false)
	
				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

			  } else {
				setMessage(result.data.message)

				setIsError(true);
	
				setDisasbled(false)
				toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

	
			  }
			}).catch(e => {
				setMessage(e.response.data.message)

				setDisasbled(false)
	
				setIsError(true);
				toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

			}); 
	
		  }

  return (
    <div>
        <section className="page-title centred">
            <div className="pattern-layer"  style={{backgroundImage : `url('/assets/images/background/page-title.jpg')`}}></div>
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Checkout Page</h1>
                        <ul className="bread-crumb clearfix">
                        <li><i className="flaticon-home-1"></i><Link to="/">Home</Link></li>
                            <li>Checkout Page</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="checkout-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 upper-column">
                        <div className="upper-box">
                            {/* <div className="customer single-box">Returning Customer? <a href="#">Click here to Login</a></div> */}
                            {/* <div className="coupon single-box">Have a Coupon? <a href="#">Click here to enter your code</a></div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 left-column">
                        <div className="inner-box">
                            <div className="order-info">
                                <h4 className="sub-title">Your Order</h4>
                                <div className="order-product">
                                    <ul className="order-list clearfix">
                                        <li className="title clearfix">
                                            <p>Product</p>
                                            <span>Total</span>
                                        </li>
                                        {cartList()}
                                        <li className="sub-total clearfix">
                                            <h6>Sub Total</h6>
                                            <span> &#8358;{cartTotalAmount()} </span>
                                        </li>
                                        <li className="order-total clearfix">
                                            <h6>Order Total</h6>
                                            <span> &#8358;{cartTotalAmount()} </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 right-column">
                        <div className="inner-box">
                            <div className="billing-info">
                                <h4 className="sub-title">Billing Details</h4>
                                <form onSubmit={checkout} className="billing-form">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <label>Address*</label>
                                            <div className="field-input">
                                                <input type="text" name="address" value={address} onChange={ e => {setAddress(e.target.value)}} required className="address" />
                                                {/* <input type="text" name="address" /> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <div className="payment-info">
                                                <h4 className="sub-title">Proccess Order</h4>
                                                <div className="payment-inner">
                                                    <div className="btn-box">
                                                        <button href="checkout.html" className="theme-btn-two">Place Your Order<i className="flaticon-right-1"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
  )
}
