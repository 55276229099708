import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './sidenav'
import DataService from "./services/Service";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

export default function SendBroadcast() {
	const [isError, setIsError] = useState(false);
	const [subject, setSubject] = useState("");
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState('no-reply@metclub.com');
	const [from, setFrom] = useState('Metclub');
	const [buttonText, setButtonText] = useState('submit');
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
    const [image, setImage] = useState(null);
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");

    const postEmail = (e) => {
		e.preventDefault();

		var data = {
			subject,
            name,
            message,
            email,
            from
		}

        let formData = new FormData() 
			formData.append('files', image) 
			formData.append('subject', subject) 
			formData.append('name', name) 
			formData.append('message', message) 
			formData.append('email', email)
			formData.append('from', from)
 
        setButtonText('Sending...')

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        setDisasbled(true)

		DataService.sendBroadcast(formData)
		.then(result => {

            if (result.status === 200) {			

                setButtonText('Sent')

                setMessage(result.data.message)

                setIsSucess(true);
                 
                setIsError(false);
                 
                setDisasbled(false)

                toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

                resetForm();


		  } else {

            setButtonText('Failed to send')

            setMessage(result.data.message)

			setIsError(true);
			
			setDisasbled(false)
            toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

		  }
		}).catch(e => {

          
          setButtonText('Failed to send')

          setMessage(e.response.data.message)

          setIsError(true);

        setDisasbled(false)
        toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))


		});

	  }

      const resetForm = () => {
        setButtonText('Submit')
      }
	  


	  if (isSucess) {
		// return <Redirect to="/dashboard" />;
		// return <Redirect to={'/dashboard'} />;
	}

    return (
        <div>

        <div className="dashboard-container">

<Sidenav />
    <div className="dashboard-content-container" data-simplebar>
    <div className="dashboard-content-inner" >
        
        <div className="dashboard-headline">
            <h3><i className="icon-line-awesome-users"></i> Broadcast Mail</h3>

            <nav id="breadcrumbs" className="dark">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>Send Broadcast</li>
                </ul>
            </nav>
        </div>

        <div className="row">

            <div className="col-xl-12">
                <div className="dashboard-box margin-top-0">

                    <div className="headline">
                        <h3><i className="icon-feather-folder-plus"></i> Send Broadcast Email to users</h3>
                    </div>
                   

                    <div className="content with-padding padding-bottom-10">
                        			            

                            { isSucess && (<div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />                                                
                                  </div>)

						    }

                        { isError && (<div>
							<ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />
                                </div>)
                        }

						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>


                        <form onSubmit={postEmail}>
                        <div className="row">
                                {/* <div className="col-xl-12">
									<div className="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
										<img className="profile-pic" src="/assets/images/user-avatar-placeholder.jpeg" alt="" />
										<div className="upload-button"></div>
										<input className="file-upload"  onChange={e => setImage(e.target.files[0])} name="files" type="file" accept="*" id="upload" />

									</div>
                                </div> */}

                            <div className="col-xl-6">
                                <div className="submit-field">
                                    <h5>Subject</h5>
                                    <input type="text" name="subject"  value={subject} placeholder="Mail Message here" onChange={e => {setSubject(e.target.value);}} className="with-border" />
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <h5>Title</h5>
                                    <input type="text" name="title"  value={name} placeholder="Mail Title here"  onChange={e => {setName(e.target.value);}} className="with-border" />
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div className="submit-field" style={{ display: 'none' }}>
                                    <h5>Sender Email</h5>
                                    <input type="email" name="subject" readOnly  value={email}  onChange={e => {setEmail(e.target.value);}} className="with-border" />
                                </div>
                            </div>

                            

                            <div className="col-xl-12">
                                <div className="submit-field">
                                    <h5>Email Content</h5>
                                    <textarea cols="30" rows="5" name="body" value={message} placeholder="Mail Content / body here"  onChange={e => {setMessage(e.target.value);}} className="with-border"></textarea>
                                    
                                </div>
                            </div>


                            <div className="col-xl-12">
									<div className="submit-field">
										<h5>Add File Attachment</h5>
										{/* <textarea cols="30" rows="5" name="guarantor_address" value={guarantor_address} onChange={e => {setGuarantorAddress(e.target.value);}} className="with-border"></textarea> */}
										<div className="uploadButton margin-top-30">
											<input className="uploadButton-input" name="files" type="file" accept="*" onChange={e => {setImage(e.target.files[0]);}} id="upload" required />
											<label className="uploadButton-button ripple-effect" htmlFor="upload">Upload Attachment</label>
											<span className="uploadButton-file-name">Images or documents Attachment for your mail </span>
										</div>
									</div>
								</div>


                            </div>

                            <div className="col-xl-12">
                                <button type="submit" className="btn btn-success btn-lg ripple-effect big margin-top-30" disabled={disabled} ><i className="icon-feather-plus"></i> {buttonText}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>



        </div>

        <DashboardFooter />

    </div>
</div>
        </div>
    </div>
    )
}
