import React, {useMemo, useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "./services/Service";
import { Card, Logo, Form, Success, Input, Button, Error } from "./AuthForms";

import {
	Container,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import TablePrintContainer from "./tableprintcontainer";
import { SelectColumnFilter } from './filters';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';


export default function MemberSpending() {
	
    const [orders, setOrders] = useState([]);

	const [startDate, setStartDate] = useState(new Date());

	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState(false);
	const [validateMessage, setValidateMessage] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
 
	useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    

		loadStatment();

      }, []);

	  const config = {
		headers: {
			Authorization : 'Bearer '+ localStorage.getItem('token')
		}
	}
	  const loadStatment = () => {
		var data = {
			year: '',
		  }

		  DataService.totalAmountSpent(data, config)
				.then(result => {
				if (result.status === 200) {
					setOrders(result.data.data)		
				} 
			});
	}

	//   statement submission
	const submitStatement = (e) => {
        e.preventDefault();

		var data = {
			year: convertDateToString(startDate),
        }

		setDisasbled(true);

		DataService.totalAmountSpent(data, config)
	  .then(result => {
        if (result.status === 200) {
			setOrders(result.data.data)
			
			setMessage(result.data.message)

			setIsSucess(true);

			setIsError(false);

			setDisasbled(false)

		} 
		else {
			setMessage(result.data.message)

			setIsError(true);
			setDisasbled(false)

		}
	}).catch(e => {

		setMessage(e.response.data.message)
		setValidateMessage(e.response.data)
		setIsError(true);
		setDisasbled(false)

	});
}

	  const convertDateToString = (date) => 
	  {
		var date = new Date(date);
    
  		return date.getFullYear();
	  }

	//   const getMember = (number) => 
	//   {
	// 	// var data = {
	// 	// 	year: convertDateToString(startDate),
    //     // }

	// 	DataService.memberUser(number, config)
	//   .then(result => {
    //     if (result.status === 200) {

	// 		setOrders(result.data.data)
			
	// 		setOpeningBalance(result.data.data.openingBalance)
	// 		setMessage(result.data.message)

	// 		setIsSucess(true);

	// 		setIsError(false);

	// 		setDisasbled(false)

	// 	} 
		

    
		const columns = useMemo(
			() => [
                {
                    Header: "Member Number",
                    accessor: "customernumber",
                    disableSortBy: true,
                    filter: 'equals',
                },
                // {
                //     Header: "Name",
                //     accessor: (values) => {
                //         // return values.user.firstname + ' '+ values.user.lastname;

                //         }
                // },
				{
                    Header: "Credit Amount",
                    accessor: "creditamount",
                    filter: 'equals',
                    Filter: SelectColumnFilter,
				},
                {
                    Header: "Debit Amount",
                    accessor: "debitamount",
                    filter: 'equals',
                    Filter: false,
                },
			],
		)
    return (
        <div>
            <div className="dashboard-container">

			<Sidenav />
           
		    <div className="dashboard-content-container" data-simplebar>
			<div className="dashboard-content-inner" >
			

			<div className="dashboard-headline">
				<h3>Members Yearly Spendings</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Orders</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">

				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-line-awesome-group"></i> Members Yearly Spendings</h3>
						</div>

						<div className="content with-padding padding-bottom-0">
							
						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>

						<Container style={{ marginTop: 100 }}>
						<form onSubmit={submitStatement}>
									<div style={{float: "right"}}>
										<span style={{display:"inline-block"}}>
											<DatePicker
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												showYearPicker
      											dateFormat="yyyy"
												placeholderText="start date"
											/>
										</span>

											<span style={{display:"inline-block", marginLeft: "5px"}}>
												<button type="submit" className="btn btn-success ripple-effect" disabled={disabled} ><i className="icon-feather-search"></i> Submit  </button>
											</span>
									</div>
								</form>
							
							
							{orders.length == 0 ? (<div align="center"> <img src="/assets/images/no_data1.gif" /> </div>) : 
								<TablePrintContainer
								columns={columns}
								data={orders}
							/>
						}

						</Container>



						</div>
					</div>
				</div>

						
				
				
				
                </div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}