import http from "./common/http-common";

const halls = () => {
    
    return http.get("/halls");
  };

  const getHalls = (data, config=null) => {
    
    return http.get("/halls", data, config);
  };

  const addHalls = (data, config=null) => {
    
    return http.post("/store/hallcenter", data, config);
  };

  const calendar = (config=null) => {
    
    return http.get("/events", config);
  };

const addCalendar = (data, config=null) => {
    
  return http.post("/store/event", data, config);
};

const removeCalendar = (id, config=null) => {

  return http.delete(`/remove/${id}`, config)
}

const OrderHall = (data, config=null) => {
  return http.post('/add/orderhall',  data, config)
}

const getProducts = () => {
    
  return http.get("/products");
};

const addProducts = (data, config=null) => {
    
  return http.post("/add/product", data, config);
};

const addPresident = (data) => {
    
  return http.post("/store/president", data);
};

const getPresident = (data) => {
    
  return http.get("/presidents", data);
};

const deceasedPresident = (id) => {
    
  return http.post(`/deceased/president/${id}`);
};

const notDeceasedPresident = (id) => {
    
  return http.post(`/notdeceased/president/${id}`);
};

const destroyPresident = (id) => {
    
  return http.delete(`/president/${id}`);
};

const addTrustee = (data) => {
    
  return http.post("/store/trustee", data);
};

const getTrustee = (data) => {
    
  return http.get("/trustees", data);
};

const deceasedTrustees = (id) => {
    
  return http.post(`/deceased/trustee/${id}`);
};

const notDeceasedTrustees = (id) => {
    
  return http.post(`/notdeceased/trustee/${id}`);
};

const destroyTrustees = (id) => {
    
  return http.delete(`/trustees/${id}`);
};

const addCommittee = (data) => {
    
  return http.post("/store/committee", data);
};

const getCommittee = (data) => {
    
  return http.get("/committee", data);
};

const destroyCommittee = (id) => {
    
  return http.delete(`/committee/${id}`);
};

  
  export default {
    halls,
    getHalls,
    addHalls,
    calendar,
    addCalendar,
    OrderHall,
    getProducts,
    addProducts,
    addPresident,
    getPresident,
    deceasedPresident,
    notDeceasedPresident,
    destroyPresident,
    getTrustee,
    addTrustee,
    deceasedTrustees,
    notDeceasedTrustees,
    destroyTrustees,
    addCommittee,
    getCommittee,
    destroyCommittee,
    removeCalendar
  };