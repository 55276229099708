import React, {useEffect, useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import DataService from "./services/Service";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { Card, Logo, Form, Success, Input, Button, Error } from "./AuthForms";


export default function ProfilePicture(props) {

    const {user} = props;

    const [profilepicture, setProfilepicture]= useState('');
	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState('');
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");


    const postPicturePicture = (e) => {

		e.preventDefault();

        const formData = new FormData() 

		formData.append('image', profilepicture) 
		
		const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
		}

        setDisasbled(true)


		DataService.profilePicture(formData, config)
		.then(result => {

            if (result.status === true) {

                setMessage(result.data.message)

                setIsSucess(true);
    
                setDisasbled(false)
    
                } 
                else {
                setMessage(result.data.message)
    
                setIsError(true);
    
                setDisasbled(false)
              }
            }).catch(e => {

                setMessage("Profile picture not updated Try Again Later")
                
                setDisasbled(false)
    
                  setIsError(true);
            });
	  }

    let placeholderImage= "/assets/images/user-avatar-placeholder.jpeg";

    return (
        <div>
						{isSucess && <Success>{message}</Success>}

                        { isError &&<Error> {message}</Error> }

                        <LoadingOverlay
                            active={disabled}
                            spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                            text='Loading...'
                        >
                        </LoadingOverlay>



            <form onSubmit={postPicturePicture}>
                <div className="col-auto">
                    <div className="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
                        <img className="profile-pic" src={user && user.image !=null ? user.image :  placeholderImage} alt="" />
                        <div className="upload-button"></div>
                        <input className="file-upload" required onChange={e => {setProfilepicture(e.target.files[0]);}} type="file" accept="image/*"/>
                    </div>
                    <button className="btn btn-primary btn-sm" disabled={disabled}> upload</button>
                </div>
            </form>
            <br />
        </div>
    )
}

