import React from 'react'
import { Link} from 'react-router-dom';

function Footer(){
    return (
        <div>
            <footer className="main-footer light">
                <div className="footer-top">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-lg-5 col-md-12 col-sm-12 big-column">
                                <div className="row clearfix">
                                    <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                                        <div className="footer-widget logo-widget">
                                            <figure className="footer-logo">
                                                <Link to={'/'}>
                                                    <img className="img-fluid" src="/assets/images/metro-logo-small.png"  alt="" />
                                                </Link>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 footer-column">
                                        <div className="footer-widget links-widget">
                                            <div className="widget-title">
                                                <h3>Useful Link</h3>
                                            </div>
                                            <div className="widget-content">
                                                <ul className="links-list clearfix">
                                                <li>
                                                    <Link to="membership">
                                                        Membership
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="services">
                                                        Services
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="gallery">
                                                        Gallery
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="contact">
                                                        Contact
                                                    </Link>
                                                </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 big-column">
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                                        <div className="footer-widget contact-widget">
                                            <div className="widget-title">
                                                <h3>Contact</h3>
                                            </div>
                                                <ul className="info-list clearfix">
                                                    <li><i className="flaticon-maps-and-flags"></i>15 Kofo Abayomi Street, Victoria Island Lagos, Nigeria.</li>
                                                    <li><i className="flaticon-phone-ringing"></i><a href="tel:+2348093107291"> +234 809 310 7291 </a></li>
                                                    <li><i className="flaticon-email"></i><a href="mailto:admin@metclub.ng">admin@metclub.ng</a></li>
                                                </ul>
                                            </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 footer-column">
                                        <div className="footer-widget newsletter-widget">
                                            <div className="widget-title">
                                                <h3>Newsletter</h3>
                                            </div>
                                            <div className="widget-content">
                                                <p>15 Kofo Abayomi Street, Victoria Island Lagos, Nigeria</p>
                                                <form action="#" method="post" className="newsletter-form">
                                                    <div className="form-group">
                                                        <input type="email" name="email" placeholder="Enter your email" required="" />
                                                        <button type="submit" className="theme-btn-three">Subscribe</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container clearfix">
                        
                        <div className="copyright pull-right">
                            <ul className="footer-social clearfix">
                                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fab fa-vimeo-v"></i></a></li>
                                <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                            </ul>
                            <p>
                                &copy; {(new Date().getFullYear())} <strong>MetClub</strong>. All Rights Reserved. 
                                <span align="right">powered by <strong> Buildingblocks </strong> </span>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;
