import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './sidenav'
import DataService from "./services/Events";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

export default function Committee() {

	const [isError, setIsError] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	const [isSucess, setIsSucess] = useState(false);
	const [message, setMessage] = useState(false);

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");


	const [name, setName] = useState("");
	const [title, setTitle] = useState("");


    const [committee, setCommittee] = useState([]);


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  

    useEffect(() => {
        

        getCommittees();

      }, []);


      const getCommittees =() => {

        DataService.getCommittee()
		.then(result => {

			if (result.status === 200) {

				setCommittee(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }
 


    const postCommitee = (e) => {
		e.preventDefault();

		var data = {
            name,
            title,
		}
        setDisasbled(true);

		DataService.addCommittee(data)
		.then(result => {

			if (result.status === 201) {			

                setMessage(result.data.message)
                setIsSucess(true);
                setDisasbled(false)
                toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));
            } 
            else {
                setMessage(result.data.message)
                setIsError(true);
                setDisasbled(false)
                toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

            }
		}).catch(e => {

            setMessage(e.response.data.message)
            setIsError(true);
            setDisasbled(false)
            toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

		});


	  }

      const deleteCommittee = (id) => {

		DataService.destroyCommittee(id)
		.then(result => {
			if (result.status === 200) {

                
                window.location.reload();
                setIsSucess(true);


			} else {
			setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});


	}


      const renderHeader = () => {
        let headerElement = ['id', 'name', 'title', 'operation']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return committee && committee.map(({ id, name, title }) => {
            return (
                <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>{title}</td> 
                    <td className='operation'>
                        {
						 <button style={{ margin:"10px"  }} className="btn btn-sm btn-danger " onClick={() => deleteCommittee(id)}> Delete </button> 
						}
                    </td>  
                   
					 
                </tr>
            )
        })
    }
      
    return (
        <div>

        <div className="dashboard-container">

<Sidenav />
    <div className="dashboard-content-container" data-simplebar>
    <div className="dashboard-content-inner" >
        
        <div className="dashboard-headline">
            <h3><i className="icon-line-awesome-users"></i> Add Club Committee</h3>

            <nav id="breadcrumbs" className="dark">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>Add Club Committee</li>
                </ul>
            </nav>
        </div>

        <div className="row">

            <div className="col-xl-12">
                <div className="dashboard-box margin-top-0">

                    <div className="headline">
                        <div>
                            {/* <h3><i className="icon-feather-folder-plus"></i> Add Club Committee </h3> */}
                            <div style={{ float: 'right' }}> <button className="btn btn-primary btn-sm" onClick={onOpenModal}> <i className="icon-feather-plus"></i> Add Club Committee </button> </div>

                        </div>
                    </div>

                    <div className="previewProfilePic">
              </div>


              <Modal open={open} onClose={onCloseModal} center>
                            <h2>Add Club Committee</h2> <br />
                            <div className="content with-padding padding-bottom-10">

                            { isSucess && (<div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />
                                  {/* { setTimeout(function(){location.reload()}, 3000)} */}
										</div>)
						    }

                            { isError && (<div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />
										</div>)

						    }
						 
                            <LoadingOverlay
                                active={disabled}
                                spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                                text='Loading...'
                            >
                            </LoadingOverlay>

                                <form onSubmit={postCommitee}>
                                    <div className="row">

                                        <div className="col-xl-6">
                                            <div className="submit-field">
                                                <h5>Committee Name</h5>
                                                <input type="text" name="name"  value={name} onChange={e => {setName(e.target.value);}} className="with-border" required />
                                            </div>
                                        </div>

                                        <div className="col-xl-6">
                                            <div className="submit-field">
                                                <h5>Title</h5>
                                                    <div className="input-with-icon">
                                                        <input name="title"  value={title} onChange={e => {setTitle(e.target.value);}} className="with-border" type="text" placeholder="Title"  />
                                                    </div>
                                            </div>
                                        </div> 

                                        </div>

                                        <div className="col-xl-12">
                                            <button type="submit" className="btn btn-success btn-lg ripple-effect big margin-top-30"  disabled={disabled}><i className="icon-feather-plus"></i> Add Committee</button>
                                        </div>
                                </form>
                            </div>
                        </Modal>



    
                    <div className="content with-padding padding-bottom-10">
                       
                            <>
                                 <h1 align="center"> List Of Mangement Committee </h1>

								<table id='employee'>
									<thead>
										<tr>{renderHeader()}</tr>
									</thead>
									<tbody>{renderBody()}</tbody>
								</table>
							</>

                    </div>
                </div>
            </div>




        </div>

        <DashboardFooter />

    </div>
</div>
        </div>
    </div>
    )
}
