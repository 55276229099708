import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav';
import { Card, Logo, Form, Input, Button, Error, Success } from "./AuthForms";
import DataService from "./services/Service";
import ProfilePicture from './profilePicture';
import PasswordUpdate from './passwordUpdate';
import Dataservice from "./services/User";


export default function Settings() {


	const [isError, setIsError] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
	const [password, setPassword] = useState("");
	const [oldpassword, setOldPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const [user, setUser] = useState([]);



    useEffect(() => {

		getUser()

      }, []);


	  const config = {
		headers: {
			Authorization : 'Bearer '+ localStorage.getItem('token')
		}
	}
	
	  	const getUser = () => 
		{
			Dataservice.user(config).then(
				res => {

				const response = res.data.data;

				setUser(response);

			}).catch(
				err => {
					// console.log(err)
				}
			)

		}
      
	  const postPassword = (e) =>{
		e.preventDefault();

		var data = {
			password,
			oldpassword,
			confirm_password,
		}

		const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
        }
		
		DataService.updatepassword(data, config)
		.then(result => {

			if (result.status === 200) {

           setMessage(result.data.message)

		   setIsSucess(true);
            setIsError(false);

		  } else {

            setMessage(result.data.message)

			setIsError(true);
		  }
		}).catch(e => {
            setMessage(e.response.data.message)

		  setIsError(true);
		});

	  }


      return (
        <div>
            

            <div className="dashboard-container">

			<Sidenav />

            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			
			<div className="dashboard-headline">
				<h3>Settings</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Settings</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">

				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-material-outline-account-circle"></i> My Account</h3>
						</div>

						<div className="content with-padding padding-bottom-0">

							<div className="row">


								<ProfilePicture user={user} />

							</div>

						</div>
					</div>
				</div>

					
				</div>				


			<div className="row">


				<div className="col-xl-12">
					
					<PasswordUpdate />

				</div>

				

				<div className="row">

				<div className="col-xl-12">

					{/* <SocialUpdate /> */}

					</div>
				</div>

			</div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}