import React, {useEffect} from 'react'
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'

export default function EventBooking() {
    useEffect(() => {
        let scripts = [
            { src: "/assets/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    
      }, []);
      
      return (
        <div>
            <div className="dashboard-container">

            <Sidenav />
            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			
			{/* <!-- Dashboard Headline --> */}
			<div className="dashboard-headline">
				<h3>Ecommerce</h3>

				{/* <!-- Breadcrumbs --> */}
				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Settings</li>
					</ul>
				</nav>
			</div>
	

			{/* <!-- Row --> */}
			<div className="row">

				{/* <!-- Dashboard Box --> */}
				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						{/* <!-- Headline --> */}
						<div className="headline">
							<h3><i className="icon-material-outline-home"></i>Hall Booking</h3>
						</div>

						<div className="content with-padding padding-bottom-0">
							
<div className="container">
  <main className="grid">
    <article>
      <img src="https://vangogh.teespring.com/v3/image/-OpCH5guL7iRy6xu4D7E7xWz9K8/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Police Unit K-9</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 13 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/police-unit-k-9?cid=2759&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/EzMTyEjKh-lwGS0DEHSCd31VwRE/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>If you call one dog</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 9 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/new-if-you-call-one-dog-you-in?cid=2753&page=1&pid=46&sid=front&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/bK43tppPNyqAOvmwmpzkMQxbvCo/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Husky</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 19 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/husky-2945?cid=2752&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/8KXofRZsEIbiARS5hTN7XbGl-0Y/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Life is too short</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 12 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/new-life-is-too-short-to-just?cid=2739&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/j7DemEbwCEV6GAmDyjZUhvqmC6s/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Beware of dog</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 20 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/beware-of-dog-4503?cid=2756&page=1&pid=46&sid=front&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/913eK8V6wtGK4SkxJSTJDin2yCI/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Did Someone Say Walk ?</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 8 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/new-did-someone-say-walk?cid=2753&page=1&pid=46&sid=front&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/JacE4rqPe8_g1OHuhxkse4oHCSk/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>The world is my canvas</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 12 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/the-world-is-my-canvas-2019?cid=2744&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/NyIVcQ8H1ZddoIJ4GaRpkbXEF_0/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>I Love My Dog</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 13 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/i-love-my-dog-7671?cid=2739&page=1&pid=46&sid=front&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/tC0GMw6YP_eMTf0-3mXw0TZ_V18/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>My therapist has paws</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 14 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/new-my-therapist-has-paws-and?cid=103391&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/isOCz7gsi6pnXvgAu1rSgjC9aJs/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Daisy the flower dog</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 14 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/daisy-the-flower-dog?tsmac=store&tsmic=dog-lover-graphic-design&pid=2&cid=6046&sid=front"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/Lh1U0IhMnRta1M3VfHBGfc_4SvM/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Golden retriever</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 8 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/golden-retriever-4834?page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/hY4yzpmqVedVrPihwhIMBQnx-OE/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Puppy dog logo</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 11 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/puppy-dog-september-2019?page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design&cid=2740"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/a2AcclBxW3cRoi6RdquKrdZOWak/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>We must speak</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 12 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/we-must-speak-for-those-who-ca?cid=103391&page=1&pid=46&sid=front&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/_MJgqBh4RhQ3CYirnSLIkzMOej0/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Mother of dogs</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 1 color is 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/mother-of-dogs-september-2019?page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
    <article>
      <img src="https://vangogh.teespring.com/v3/image/hyQnctk92EA1iu0rq3YkSWVN3Gw/480/560.jpg" alt="tshirt photo" />
      <div className="text">
        <h3>Did I Do That?</h3>
        <p>by <a href="https://teespring.com/stores/dog-lover-graphic-design">Dog Lover Graphic Design</a></p>
        <p>Material: 4.3 oz., 8 colors are 100% combed ringspun cotton. Machine Wash Cold, Tumble Dry Low. Sizing offered: XS - 3XL</p>
        <a href="https://teespring.com/fr/new-did-i-do-that?cid=101361&page=1&pid=46&tsmac=store&tsmic=dog-lover-graphic-design"
           className="btn btn-primary btn-block">Add to cart</a>
      </div>
    </article>
  </main>
</div>

 <br />
						</div>
					</div>
				</div>

						
				
                {/* </div> */}

			</div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}