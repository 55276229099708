import React, {useEffect, useState} from 'react'
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import { Link, Redirect } from 'react-router-dom';
import DataService from "./services/Events";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import DatePicker from "react-datepicker";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

export default function Calendar() {

    const [calendar, setCalendar] = useState([]);
    
    const [isError, setIsError] = useState(false);
    const [disabled, setDisasbled] = useState(false)
    const [isSucess, setIsSucess] = useState(false);
    const [message, setMessage] = useState(false);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("green");
    const [startDate, setStartDate] = useState(new Date());
    const [title, setTitle] = useState('');
    const [isHoliday, setIsHoliday] = useState('0');

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  

    useEffect(() => {
        

        getCalendar();

      }, []);


      const config = {
        headers: {
          Authorization : 'Bearer '+ localStorage.getItem('token')
        }
      }

      const getCalendar =() => {

        DataService.calendar(config)
        .then(result => {

          if (result.status === 200) {
            setCalendar(result.data.data);
          } else {
          setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
      }

      const postCalendar = (e) => {
        e.preventDefault();

        var data = {
          start: startDate,
          title,
          is_holiday:isHoliday
        }

        setDisasbled(true);

      DataService.addCalendar(data, config)
      .then(result => {

        if (result.status === 201) {

          setMessage(result.data.message)
 
          setIsSucess(true);

          setDisasbled(false)

          toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));
      } 
      else {
          setMessage(result.data.message)

          setIsError(true);
          setDisasbled(false)
          toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

      }
      }).catch(e => {
            setMessage(e.response.data.message)
            setDisasbled(false)
            setIsError(true);
            toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

      });
	  }

      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
   
      return (
        <div>
            
            <div className="dashboard-container">

            <Sidenav />

            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			

			<div className="dashboard-headline">
				<h3>Calendar</h3>


				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Settings</li>
					</ul>
				</nav>
			</div>
	


			<div className="row">


				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-material-outline-shopping-cart"></i> Calendar </h3>
                            <div style={{ float: 'right' }}> <button className="btn btn-primary btn-sm" onClick={onOpenModal}> <i className="icon-feather-plus"></i> Add Calendar</button> </div>

						</div>

						<div className="content with-padding padding-bottom-0">
                        <Modal open={open} onClose={onCloseModal} center>
                            <h2>Add Calendar</h2> <br />

                            { isSucess && (<div>
                                  <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />
                                        </div>)

                            }

                            { isError && (<div>
                              <ToastContainer
                                  position="top-right"
                                  autoClose={5000}
                                  hideProgressBar={false}
                                  newestOnTop={false}
                                  closeOnClick
                                  rtl={false}
                                  pauseOnFocusLoss
                                  draggable
                                  pauseOnHover
                                  />
                                </div>)

                            }

                            <LoadingOverlay
                                active={disabled}
                                spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                                text='Loading...'
                            >
                            </LoadingOverlay>


                            <form onSubmit={postCalendar}>
                                    <div className="row">

                                    <div className="col-xl-6">
                                      <div className="submit-field">
                                        <h5>Date</h5>
                                        <DatePicker  dateFormat="yyyy-MM-dd" selected={startDate} onChange={date => setStartDate(date)} />
                                      </div>
                                    </div>
                                    <div className='col-xl-12'>
                                      <div className="submit-field">
                                        <h5>Is Holiday</h5>
                                        <div className="input-with-icon">
                                          <select value={isHoliday} onChange={e => {setIsHoliday(e.target.value);}}>
                                            <option value='1'> Yes </option>
                                            <option value='0'> No </option>
                                          </select>
                                        <i className="icon-feather-users"></i>
                                      </div>
                                    </div>
                                    </div>

                                    <div className="col-xl-12">
                                        <div className="submit-field">
                                            <h5>Title</h5>
                                            <input type="text" name="title"  value={title} onChange={e => {setTitle(e.target.value);}} className="with-border" />
                                        </div>
                                    </div>

                                    </div>

                                    <div className="col-xl-12">
                                        <div style={{ float:"right" }}>
                                          <button type="submit" className="btn btn-success btn-lg ripple-effect big margin-top-30" disabled={disabled} ><i className="icon-feather-plus"></i> Submit  </button>
                                        </div>
                                        
                                    </div>
                            </form>
                        </Modal>
    
   
                        <FullCalendar 
                        plugins={[dayGridPlugin, interactionPlugin]}
                        editable={true}                        
                        initialView="dayGridMonth"
                        events={calendar} />

				<br />
				
						</div>
					</div>
				</div>

			</div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}