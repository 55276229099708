import React, {useEffect, useState} from 'react'
// import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DataService from "./services/Service";
import 'react-responsive-modal/styles.css';
import { Card, Logo, Form, Success, Input, Button, Error } from "./AuthForms";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";

export default function EcommerceProduct() {

	const [products, setProducts] = useState([]);
	const [quantity, setQuantity] = useState(1);
	const [message, setMessage] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
 
    useEffect(() => {
		getProducts();
      }, []);

	  const getProducts =() => {
        DataService.allProducts()
		.then(result => {
			console.warn(typeof result.data.data)
			if (result.status === 200) {
				setProducts(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }

	const addToCart = (id, amount) => {
		var data = {
            amount: amount,
			product_id:id
		}
		
		const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
		}

		setDisasbled(true);

		DataService.addToCart(data, config)
		.then(result => {

			if (result.status === 200) {
				setMessage(result.data.message)

				setIsSucess(true);
	 
				setIsError(false);
	 
				setDisasbled(false)

				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));
	 
			   } else {
	 
				 setMessage(result.data.message)

				 setIsError(true);
	 
				 setDisasbled(false)
				 toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))
			   }
			 }).catch(e => {
	 
				setMessage(e.response.data.message)

				setIsError(true);
		
				setDisasbled(false)
				toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))
			 });
		   }

	  const productList = () => {
        return products && products.map(({ id, name, image, unit, amount, is_available, description }) => {
            return (
				<div className="col-lg-3 col-md-6 col-sm-12 shop-block" key={id}>
					<div className="shop-block-one">
						<div className="inner-box">
							<figure className="image-box">
								<img src={image} style={{ height:"270px", width:"320px" }} className="img-fluid" alt="product" />
								<ul className="info-list clearfix">
									{/* <li><a href="index.html"><i className="flaticon-heart"></i></a></li> */}
									<li></li>
									<li> <a onClick={() => addToCart(id, amount)} disabled={disabled}> Add to cart </a></li>
									{/* <li><a href={image} className="lightbox-image" data-fancybox="gallery"><i className="flaticon-search"></i></a></li> */}
								</ul>
							</figure>
							<div className="lower-content">
								<a href="#">{name}</a>
								<span className="price">&#8358; {amount}</span>
							</div>
						</div>
					</div>
				</div>
			)
        })
    } 
	
      return (
        <div>
            <div className="dashboard-container">
				<Sidenav />
				<div className="dashboard-content-container" data-simplebar>
					<div className="dashboard-content-inner" >
						<div className="dashboard-headline">
							<h3>Products</h3>
							<nav id="breadcrumbs" className="dark">
								<ul>
									<li><a href="#">Home</a></li>
									<li><a href="#">Dashboard</a></li>
									<li>Products</li>
								</ul>
							</nav>
						</div>
				
						
						<div className="row">
							<div className="col-xl-12">
								<div className="dashboard-box margin-top-0">
									<div className="headline">
										<h3><i className="icon-material-outline-shopping-cart"></i> Products</h3>
									</div>
									{ isSucess && (<div>
										<ToastContainer
											position="top-right"
											autoClose={5000}
											hideProgressBar={false}
											newestOnTop={false}
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable
											pauseOnHover
										/>
										</div>)

										}

										{ isError && (<div>
											<ToastContainer
												position="top-right"
												autoClose={5000}
												hideProgressBar={false}
												newestOnTop={false}
												closeOnClick
												rtl={false}
												pauseOnFocusLoss
												draggable
												pauseOnHover
											/>
											</div>)
										}

									<LoadingOverlay
										active={disabled}
										spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
										text='Loading...'
									>
									</LoadingOverlay>

									<section className="shop-page-section shop-page-1">
										<div className="auto-container">
											<div className="our-shop">
												<div className="row clearfix">
												{productList()}
													
												</div>
											</div>
											{/* <div className="pagination-wrapper centred">
												<ul className="pagination clearfix">
													<li><a href="shop.html">Prev</a></li>
													<li><a href="shop.html">1</a></li>
													<li><a href="shop.html" className="active">2</a></li>
													<li><a href="shop.html">3</a></li>
													<li><a href="shop.html">4</a></li>
													<li><a href="shop.html">5</a></li>
													<li><a href="shop.html">Next</a></li>
												</ul>
											</div> */}
										</div>
									</section>
									
								</div>
							</div>
						</div>
						<DashboardFooter />
					</div>
				</div>
			</div>
        </div>
    )
}