import React from 'react'
import Header from './header';
import Footer from './footer';
import Aboutuspreview from './aboutuspreview';
import Slider from './slider';
import Banner from './banner';

function Index()  {

    return (
        <div>
            <div className="page-wrapper">
            {/* <Header /> */}
            <Slider />
            {/* <Banner /> */}
            <Aboutuspreview />
            <Footer />
            </div>
        </div>
    )
}

export default Index;