import React, { Component } from 'react'
import Footer from './footer'
import Header from './header'
import {Link} from 'react-router-dom';

export default class Membership extends Component {
 
    constructor(props){
        super(props);
        this.imgUrl_1 = '/assets/images/membership.JPG';
        this.style_1 = {
            backgroundImage: 'url("'+this.imgUrl_1+'")'
        };
    }
    render() {


        return (
            <div>
                    <section className="page-title centred">
                        <div className="pattern-layer"  style={{backgroundImage : `url('/assets/images/background/page-title.jpg')`}}></div>
                        <div className="auto-container">
                            <div className="content-box">
                                <h1>Membership</h1>
                                <ul className="bread-crumb clearfix">
                                    <li><i className="flaticon-home-1"></i><Link to="/">Home</Link></li>
                                    <li>membership</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="service-page-section">
                        <div className="auto-container">
                            <div className="service-block-two mb-100">
                                <div className="inner-box">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                                            <figure className="image-box mr-30"><img src="/assets/images/membership.JPG" alt="" /></figure>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                                            <div className="content-box ml-30 mr-100">
                                                <h2><a href="service.html">Membership</a></h2>
                                                <p>
                                                    The Met Club membership is strictly “by invitation only” in the tradition of fine private clubs. 
                                                    The President of the Club is in charge of the Management of the club, assisted by the Management Committee Members. 
                                                    The quality and seniority of members is the no. 1 priority of the Club. 
                                                    Members’ proposed must be recommended by the Membership- subcommittee and approved by the Management Committee. 
                                                    The Sub-committee’s task is to help the roster of the club member to grow steadily and advise and assist the Management Committee, 
                                                    so that the balance and quality of members is always maintained, taking into consideration the Club’s Rule 2b.
                                                </p>
                                                <p>
                                                    The Met Club offers membership in a number of categories: 
                                                </p>
                                                    <ul className='listStyle'> 
                                                        <li>Ordinary Membership </li>
                                                        <li>Country Membership </li>
                                                        <li>Honorary Membership </li>
                                                        <li>Diplomatic Membership </li>
                                                        <li>Overseas Membership </li>
                                                        <li>Temporary Membership </li>
                                                        <li>Supernumerary Membership </li>
                                                    </ul>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                <Footer />
            </div>
        )
    }
}
