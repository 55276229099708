import React, {useEffect, useState} from 'react'
import Footer from './footer'
import Header from './header'
import {Link} from 'react-router-dom';
import DataService from "../dashboard/services/Service";


export default function President_club() {

	const [president, setPresident] = useState([]);
    const [isError, setIsError] = useState(false);


    useEffect(() => {
 
        getPresident();

      }, []);

      const getPresident =() => {
        DataService.allPresident()
        .then(result => {

            if (result.status === 200) {
                setPresident(result.data.data);
          } else {
            setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
      }
      
      const firstFourCharacter= (str) => 
      {
        if (str == null)
        {
            return 'till date'; 
        }
        return str.substring(0,4);
      }


      const renderHeader = () => {
        let headerElement = [ 'name', 'Year', ]

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return president && president.map(({ id, name, title, year_from, year_to, is_deceased}) => {
            return (
                <tr key={id} style={{ color: 'white' }}>
                    {/* <td>{name} {is_deceased ? '*' : ''}  <br /> <b> {title} </b> </td>  */}
                    <td>{name} {is_deceased ? '*' : ''} </td> 
                    <td>{title}</td> 
                    <td>{firstFourCharacter(year_from)} - {firstFourCharacter(year_to)}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
        })
    }

    return (

      <div>
        <section className="history-section" style={{ background: '#3f0d12' }} >
          <div className="auto-container">
            <div align= 'center'><img src="/assets/images/background/metclub_eblem.png" /> </div>

            <div className="sec-title">
                <h2 style={{ color:'white' }}> PRESIDENTS OF THE CLUB </h2>
                <div className="separator"></div>
            </div>

          <div className="centerborderless">
            <div className="row clearfix"> 
              <>
                <table className="table table-responsive borderless">
                  <thead>
                    {/* <tr>{renderHeader()}</tr> */}
                  </thead>
                  <tbody>{renderBody()}</tbody>
                </table>

                <p style={{ color:'white', float:'left' }}>
                  * indicates Deceased
                </p>
              </>
            </div> 
          </div> 
          </div>

        </section>
        <Footer />
      </div>
    )
}