import React, {useMemo, useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DataService from "./services/Service";
import {
	Container,
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import TableContainer from "./tablecontainer"
import { SelectColumnFilter } from './filters';



export default function Users() {

	const [isSignup, setIsSignup] = useState(false);
	const [isError, setIsError] = useState(false);
	const [users, setUsers] = useState([]);


	useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    

		DataService.getUsers()
		.then(result => {
		if (result.status == 200) {
			setUsers(result.data.data);
			setIsSignup(true);
		} 
		else {
			setIsError(true);
		}
		}).catch(e => {
		  setIsError(true);
		});

	  
      }, []);



	  const renderRowSubComponent = (row) => {
		const {
			firstname,
			lastname,
			national_award,
			member_type,
			image,
			phone,
			date_of_birth,
			home_address,
			office_address,
		} = row.original;

		let defaultAvartar ="/assets/images/user-avatar-placeholder.jpeg";

		var profilepicture = image == null ? defaultAvartar : image

		return (
			<Card style={{ width: '20rem', margin: '0 auto'}}>
				<div align="center">
					<CardImg top src={profilepicture} alt='Card image cap' />
					<CardBody>
						<CardTitle>
							<strong>{`${firstname} ${lastname}`} </strong>
						</CardTitle>
						<CardText>
							<strong>Age</strong>: { totalMemberYearDifference(new Date(), new Date(date_of_birth))} <br />
							<strong>Date Of Birth</strong>: {date_of_birth} <br />
							<strong>Phone</strong>: {phone} <br />
							<strong>National Awards:</strong>  {national_award} <br />
							<strong>Home Address:</strong>  {home_address} <br />
							<strong>Office Address:</strong>  {office_address} <br />
						</CardText>
					</CardBody>
				</div>
			</Card>
		);
	  };



function totalMemberYearDifference(date1, date2) {  
	const date1utc = date1.getFullYear();
	const date2utc = date2.getFullYear();

	const day = 1000*60*60*24;

	return(date1utc - date2utc) 
  }


	const columns = useMemo(
		() => [
			{
				Header: () => null,
				id: 'expander', 
				Cell: ({ row }) => (
				  <span {...row.getToggleRowExpandedProps()}>
					{row.isExpanded ? '👇' : '👉'}
				  </span>
				),
			},
			{
			Header: "Member Number",
			accessor: "member_number",
			disableSortBy: true,
			filter: 'equals',
			},
			{
			Header: "First name",
			accessor: "firstname",
			disableSortBy: true,
			filter: 'equals',

			},
			{
			Header: "Last name",
			accessor: "lastname",
			disableSortBy: true,
			filter: 'equals',
			},
			{
			Header: "Email",
			accessor: "email",
			disableSortBy: true,
			filter: 'equals',
			// 	disableFilters: true

			},
			{
			Header: "Phone",
			accessor: "phone",
			disableSortBy: true,
			filter: 'equals'
			},
			{
			Header: "Member Subscription",
			accessor: "member_type",
			filter: 'equals',
			Filter: SelectColumnFilter,

			},
			{
			Header: "Date Invited",
			accessor: "date_invited",
			Filter: SelectColumnFilter,
			filter: 'equals'
			},
			{
			Header: "Total Membership Year",
			accessor: (values) => {
					let totalYear = totalMemberYearDifference(new Date(), new Date(values.date_invited));
					return totalYear > 0 ? totalYear : "less than a Year"
				}
			},

		],
		[]
	  )



	const removeData = (id) => {

		alert("hello " + id);

    }

      
      return (
        <div>
            <div className="dashboard-container">

<Sidenav />
            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			
			{/* <!-- Dashboard Headline --> */}
			<div className="dashboard-headline">
				<h3>All Accounts</h3>

				{/* <!-- Breadcrumbs --> */}
				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Account</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">
				{/* <!-- Dashboard Box --> */}
				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-line-awesome-group"></i> All Users</h3>
							<div style={{ float: 'right' }}><Link to={'/signup'}> <button className="btn btn-primary btn-sm" > <i className="icon-feather-plus"></i> Add Users</button> </Link> </div>

						</div>

						<div className="content with-padding padding-bottom-0">
							
						<Container style={{ marginTop: 100 }}>
							<TableContainer
								columns={columns}
								data={users}
								renderRowSubComponent={renderRowSubComponent}
							/>
							</Container>
							<br />

						</div>
					</div>
				</div>
	
                </div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}