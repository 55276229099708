import React, {useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DataService from "./services/Service";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import DatePicker from "react-datepicker";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";

export default function Signup() {
	const [isSignup, setIsSignup] = useState(false);
	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState(false);
	const [validateMessage, setValidateMessage] = useState('');
    const [errors , setErrors] = useState('');
    const [disabled, setDisasbled] = useState(false)
	const [isSucess, setIsSucess] = useState(false);
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
	const [memberNumber, setMemberNumber] = useState('');
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [middlename, setMiddlename] = useState("");
	const [officeAddress, setOfficeAddress] = useState("");
	const [homeAddress, setHomeAddress] = useState("");
	const [email, setEmail] = useState("");
	const [title, setTitle] = useState("");
	const [nationalAward, setNationalAward] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState(new Date());
	const [dateInvited, setDateInvited] = useState(new Date());
	const [password, setPassword] = useState("metclub")
	const [phone, setPhone]= useState("");
	const [image, setImage] = useState(null);
	const [memberType, setMemberType]= useState("");
	const [subscriptionPlan, setSubscriptionPlan] = useState([]);
	const [groupCodeList, setGroupCodeList]= useState([]);
	const [groupCode, setGroupCode]= useState("");
	const [taxCodeList, setTaxCodeList]= useState([]);
	const [taxCode, setTaxCode]= useState("");
	const [role, setRole] = useState('');
	const [rolesList, setRolesList] = useState([]);

	useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })  

		getMemberType();
		getGroupCode();
		getTaxCode(); 
		getRoles();

      }, []);



	const getMemberType = () => {
		DataService.subscriptionPlans()
		.then(result => {

			if (result.status === 200) {
				setSubscriptionPlan(result.data.data);
		  } else {

			setMessage(result.data.message)
    
			setIsError(true);		  }
		}).catch(e => {

			setMessage(e.response.data.message)

		  	setIsError(true);
		});
	}

	const getRoles = () => {
		DataService.roles()
		.then(result => {

			if (result.status === 200) {
				setRolesList(result.data.data);
			}
		});
	}

	const getGroupCode = () => {
		DataService.getGroupCodes()
		.then(result => {

			if (result.status === 200) {
				setGroupCodeList(result.data.data);
		  } else {

			setMessage(result.data.message)
    
			setIsError(true);		  }
		}).catch(e => {

			setMessage(e.response.data.message)

		  	setIsError(true);
		});
	}

	const getTaxCode = () => {
		DataService.getTaxCodes()
		.then(result => {

			if (result.status == 200) {
				setTaxCodeList(result.data.data);
		  } else {

			setMessage(result.data.message)
    
			setIsError(true);		  }
		}).catch(e => {

			setMessage(e.response.data.message)

		  	setIsError(true);
		});
	}

	function jsUcfirst(string) 
    {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

	let selectedSubscriptionPlan = (subscriptionPlan && subscriptionPlan.map( data => { return <option key={data.id} value={data.name}>  {jsUcfirst(data.name)} </option> }) )

	let selectedGroupTax = (taxCodeList && taxCodeList.map( (data, index) => { return (<option key={index} value={data.tax_codes}>  {data.tax_codes}  </option>) }) )

	let selectedGroupCode = (groupCodeList && groupCodeList.map( (data,index) => { return <option key={index} value={data.group_codes}> {data.group_codes} </option> }) )
	
	let selectedRole = (rolesList && rolesList.map( (data,index) => { return <option key={index} value={data}> {data +" Role"} </option> }) )

	const postSignup = (e) => {
		e.preventDefault();
		

		let date_Of_Birth = new Date(dateOfBirth).toISOString();
		let date_invited = new Date(dateInvited).toISOString();

		let formData = new FormData() 
			formData.append('image', image) 
			formData.append('member_number', memberNumber) 
			formData.append('firstname', firstname) 
			formData.append('lastname', lastname) 
			formData.append('middlename', middlename)
			formData.append('home_address', homeAddress)
			formData.append('office_address', officeAddress)
			formData.append('email', email)
			formData.append('title', title)
			formData.append('national_award', nationalAward.toUpperCase())
			formData.append('date_of_birth', date_Of_Birth)
			formData.append('date_invited', date_invited)
			formData.append('password', password)
			formData.append('phone', phone)
			formData.append('member_type', memberType)
			formData.append('group_code', groupCode)
			formData.append('tax_code', taxCode)

		const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
		
		setDisasbled(true);

		
		DataService.signup(formData, config)
		.then(result => {

			if (result.status === 201) {

				setMessage(result.data.message)

                setIsSucess(true);

                setDisasbled(false);
				setIsError(false);

				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));

            } 
            else {
                setMessage(result.data.message)

                setIsError(true);
                setDisasbled(false)
				toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

            }
		}).catch(e => {
		    setIsError(true);

			setMessage(e.response.data.message)

            setValidateMessage(e.response.data)
            setDisasbled(false)
			toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

		});

	  }



      return (
        <div>
            <div className="dashboard-container">

<Sidenav />
            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			
			<div className="dashboard-headline">
				<h3>Add Member</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Account</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">
				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-material-outline-account-circle"></i> Add Member</h3>
						</div>

						<div className="content with-padding padding-bottom-0">

							{ isSucess && (<div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />  										
								  </div>)

						    }

                            { isError && (<div>
                                <ToastContainer
                                    position="top-right"
                                    autoClose={5000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />  
										</div>)

						    }

						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>


							<div className="row">

							<form onSubmit={postSignup}>
								<div className="col">
									<div className="row">

									<div className="col-xl-12">
									<div className="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
										<img className="profile-pic" src="/assets/images/user-avatar-placeholder.jpeg" alt="" />
										<div className="upload-button"></div>
										<input className="file-upload"  onChange={e => setImage(e.target.files[0])} name="image" type="file" accept="image/*" id="upload" />
										
									</div>
									{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.image : null}</p>
										)}
								</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Membership Number</h5>
												<input type="text" value={memberNumber} onChange={e => {setMemberNumber(e.target.value);}} name="username" className="with-border" placeholder="username / member number"  required/>
												{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.member_number : null} </p>
												)}
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Title</h5>
												<input type="text" value={title} onChange={e => {setTitle(e.target.value);}} name="title" className="with-border" placeholder="Title" />
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>National Award</h5>
												<input type="text" value={nationalAward} onChange={e => {setNationalAward(e.target.value);}} name="title" className="with-border" placeholder="National Title" />
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>First Name</h5>
												<input type="text" name="firstname" value={firstname} onChange={e => {setFirstname(e.target.value);}} className="with-border" placeholder="first name" required />
											</div>
										</div>


										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Last Name</h5>
												<input type="text" name="lastname" value={lastname} onChange={e => {setLastname(e.target.value);}} className="with-border" placeholder="last name"  required/>
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Middle Name</h5>
												<input type="text" name="middlename" value={middlename} onChange={e => {setMiddlename(e.target.value);}} className="with-border" placeholder="middle name" />
											</div>
										</div>

										{/* <div className="col-xl-6">
											<div className="submit-field">
												<h5>Password</h5>
												<input type="password" name="password" value={password} onChange={e => {setPassword(e.target.value);}} className="with-border" placeholder="password" />
											</div>
										</div> */}
										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Email</h5>
												<input type="email" value={email} onChange={e => {setEmail(e.target.value);}} name="email" className="with-border" placeholder="email" />
												{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.email : null}</p>
												)}
											</div>
										</div>
										<div className="col-xl-6">
												<div className="submit-field">
															<h5>Select Role</h5>
													<div className="input-with-icon">
														<select value={role} onChange={e => {setRole(e.target.value);}} placeholder="Select User Role" required>
															<option value="customer" selected>Customer Role</option>
																	{selectedRole}
														</select>
														<i className="icon-feather-users"></i>
													</div>
												</div>
										</div>

										<div className="col-xl-6">
												<div className="submit-field">
															<h5>Member Type</h5>
													<div className="input-with-icon">
														<select value={memberType} onChange={e => {setMemberType(e.target.value);}} required>
															<option value=''> Select A Member Type </option>
																	{selectedSubscriptionPlan}
														</select>
														<i className="icon-feather-users"></i>
													</div>
												</div>
										</div>
										
										
										<div className="col-xl-6">
												<div className="submit-field">
															<h5>Tax Group Code</h5>
													<div className="input-with-icon">
														<select value={taxCode} onChange={e => {setTaxCode(e.target.value);}} required>
															<option value=''> Select Tax Code </option>
																	{selectedGroupTax}
														</select>
														<i className="icon-feather-users"></i>
													</div>
												</div>
										</div>
										{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.tax_code : null}</p>
										)}

										<div className="col-xl-6">
												<div className="submit-field">
															<h5>Group Code</h5>
													<div className="input-with-icon">
														<select value={groupCode} onChange={e => {setGroupCode(e.target.value);}} required>
															<option value=''> Select Group Code</option>
																	{selectedGroupCode}
														</select>
														<i className="icon-feather-users"></i>
													</div>
												</div>
										</div>
										{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.group_code : null}</p>
										)}

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Phone</h5>
                                                <input name="phone"  value={phone} onChange={e => {setPhone(e.target.value);}}className="with-border" type="number" placeholder="Phone" />
												{isError && (
													<p className="text-danger">* {validateMessage.errors ? validateMessage.errors.phone : null}</p>
										)}
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Date Of Birth</h5>
												<DatePicker  dateFormat="yyyy-MM-dd" selected={dateOfBirth} onChange={date => setDateOfBirth(date)} />

											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Date Of Invitation</h5>
												<DatePicker dateFormat="yyyy-MM-dd" selected={dateInvited} onChange={date => setDateInvited(date)} />
											</div>
										</div>						

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Home Address</h5>
												<div className="submit-field">
                                                <textarea cols="30" rows="5" value={homeAddress} onChange={e => {setHomeAddress(e.target.value);}} className="with-border"></textarea>
                                            </div>
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Office Address</h5>
												<div className="submit-field">
                                                <textarea cols="30" rows="5" value={officeAddress} onChange={e => {setOfficeAddress(e.target.value);}} className="with-border"></textarea>
                                            </div>
											</div>
										</div>

                                        <div className="col-xl-12">
                                            <button className="btn btn-success btn-block btn-lg ripple-effect big margin-top-30" type="submit" disabled={disabled}> Create User  </button>
                                        </div>

									</div>
								</div>

								</form>
								<br />
							</div>

						</div>
					</div>
				</div>

			</div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}