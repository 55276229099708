import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import {Link} from 'react-router-dom';
import Team from './team';

export default function Aboutus() {

        return (
            <div>
                <section className="history-section" >
                    <div className="auto-container">

                        <div className="sec-title">
                            <h2>A Word From The President</h2>
                            <div className="separator"></div>
                            <div className="text">A Word From The President</div>
                        </div>
                        
                        <div className="row president">

                            <div className=" col-md-5 col-sm-12 col-xs-12" >
                                <img src="assets/images/president.png" className='img-fluid'  alt="oloye" />
                            </div>

                            <div className="content-column col-md-7 col-sm-12 col-xs-12">
                                <div className="inner-box">
                                    <div>
                                        <p className="word">
                                        About  a  year  before  Nigeria’s  independence  from  British rule on October 1st 1960, Sir Adetokunbo Ademola with four other Nigerians  founded the  Metropolitan club ,Lagos-“ a social club of gentlemen who are at present, and are likely to continue to be, major contributors to the progress of Nigeria.”-Two  of the Nigerians were in the supreme court  with Sir Adetokunbo  Ademola, the chief justice of the federation. The other two were the chief medical officer of the federation and the chief medical officer before him, All four Dr. Dele Alakija   and   Dr. C.M. Norman Williams, Sir Lois Mbanefo and MR. Justice Daddy Onyeama, like Sir, Adetokunbo   Ademola , were old boys of kings  college, Lagos. The Expatriate (British) nationals were Mr. Robert  Fleming of the Socony (mobil) oil, Mr. E.C. Judd, chairman of U.A.C ,Chief Joe Harold of Ikeja arms hotel (now Ikeja airport hotel)and sir Leonard Diary of Barclays Bank.
                                        History has it that in the early years of our club, the quota of one half of the membership for Nigerians could not be attained because very few of our nationals were owners, let alone captains, of business and industry. With the promulgation of the Nigerian enterprises promotion decree in 1972 however, Nigerians increasingly assumed ownership and leadership of the private sector. This trend has since naturally continued to pick up pace with corresponding decrease in the number of our expatriate membership.
                                        Abuja as the political capital and the seat of government, and was made worse by the federal government’s order to expatriate companies to move their headquarters.
                                        The demography of our club has since changed forever, with fewer expatriate memberships.
                                        In-spite  of these developments however ,Lagos remains the economy capital of Nigeria, and the fourth or fifth largest economy in Africa. Lagos remains the seat  of business enterprises in Nigeria ,with the crème of businessmen and  professionals of all  hew in-tow.
                                        Our membership boasts the crème de la crème  of men in private sector, in the professions ,academia , retired and still serving public officers. And wait for it: There is at the club a table of retired diplomat!

                                        Let us go back to the raison d’être of the club as set down by the founding fathers and see how far we have gone in upholding those objects. They are:
                                        (1). A social club gentlemen.
                                        (2). The members are at present, and are likely to continue to be, major contributors to the progress of Nigeria.
                                        (3). The members shall remain in the club premises and provide facilities for the use of members and their guests. 
                                        this purpose. We take this opportunity to appeal to all members, and the 
                                        Nigeria does not keep records, our club must have its proper history written in gold.  We intend to set opportunity  to appeal to all members, and the Nigeria does not keep records, our club must  have its proper history written in gold.  We  intend to set up a history committee for  this purpose. We take this opportunity  to appeal to all members, and the children of our deceased members to kindly hand over to the club our old  files or  papers on the club, or those of our deceased relations for this project.
                                        And  our membership! We recorded a “first” in our long history when, on the 9th of august 2019 a member of the club  “hit a century”. Mr. Akintola Williams, F.C.A, CFR, our oldest living member, joined the metropolitan club in 1955.
                                        It has been suggested that we  should  expand our membership to 1000. That, in our view, will diminish the quality of our membership. We have a blend of old and young.  All we need are more expatriates members  from the private sector and the diplomatic corps or  united nations agencies. “we few, we happy few, we band of brothers”.
                                        What is still missing   is the collective opinion of the Metropolitan club on major issues affecting the country, and the presentation of such opinion  to those who direct policy.                                                                                      There is insecurity of life all around, unrest  in the oil producing  area  has not completely gone away, and worse still, the book  haram insurgency is still raging.
                                        Sixty years on, we remain “a social club of gentlemen who are at present, and are likely to continue to be major contributors to the progress of Nigeria”.
                                        Nigeria of our youth may still emerge; one nation, free and strong. A Nigeria  where every citizen, like the Chinese or the Indian, will be proud to associate himself anywhere in the world regardless  of his state or local government. I yearn for a Nigeria where those who God  has vested  with power  will be patriots is transient. I still yearn for an economically strong Nigeria, where poverty is reduced to the barest  minimum.
                                        MAY THAT NIGERIA STILL  COME TO PASS.
                                        FEMI OKUNNU  ESQ. SAN,CON.                                                                                                                                                                     PRESIDENT.
                                        </p>
                                    </div>
                                    <div className="founder">
                                        <div className="inner">
                                            <div className="image">
                                                <img src="assets/images/president.png" alt="" />
                                            </div>
                                            <h3>FEMI OKUNU  ESQ. SAN,CON</h3>
                                            <a href="#">President</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        

                </section>
                <section style={{background:'whitesmoke'}}>
                    <div style={{ marginTop: '200px', paddingTop:'20px' }}>
                        <div className="sec-title">
                            <h2>Founding Fathers</h2>
                            <div className="separator"></div>
                        </div>
                                
                        <Team />

                    </div>
                </section>

    <Footer />

    </div>
    )

}
