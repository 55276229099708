import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";


export default function LibrarySlider() {
    
      const images = [
        { url: "/assets/images/library_slider/1.jpeg" },
        { url: "/assets/images/library_slider/2.jpeg" },
        { url: "/assets/images/library_slider/3.JPG" },
      ];
    //   570 × 378 px
      const Slideshow = () => {
        return (
            <div>
            <SimpleImageSlider
              width={570}
              height={378}
              images={images}
              showBullets={true}
              showNavs={false}
              autoPlay={true}
              autoPlayDelay={3.0}
            />
          </div>
        )
    }

  return (
    <div>
        {Slideshow() }
    </div>
  )
}
