import http from "./common/http-common";

const getUsers = () => {
  return http.get("/allUsers");
};

const get = id => {
  return http.get(`/tutorials/${id}`);
};

const login = data => {

  return http.post("/login", data);
};

const signup = (data, config=null)=> {

  return http.post("/register", data, config);
};

const forgot = data => {
  return http.post("/forgot", data)
}

const reset = data => {
  return http.post("/reset", data)
}

const allProducts = () => {
  return http.get("/products")
}


const OrderItem = (data, config=null) => {
  console.warn(data)
  return http.post("/add/orders",  data, config)
}

const addToCart = (data, config) =>{
  return http.post("/cart/store",  data, config)
}

const sendBroadcast = (data) => {
    
  return http.post("/send-bulk-mail", data);
};

const allOrders = () => {
  return http.get("/orders")
}

const confirmedOrder = () => {
  return http.get("/confirmorders")
}

const totalAmountSpent =(data, config=null) =>{
  return http.post("users/totalamountspent", data, config);

}

const deliverOrder = (id) => {
  return http.patch(`/orders/${id}/deliver`)
}

const allHallOrders = () => {
  return http.get("/orderhall/hall")
}

const deliverHallOrder = (id) => {
  return http.patch(`/hall/${id}/deliver`)
}

const allPresident = () => {
  return http.get(`/presidents`)
}

const postPresident = (data) => {
  return http.post(`/store/president`, data)
}

const allTrustees = () => {
  return http.get(`/trustees`)
}

const allCommittee = () => {
  return http.get(`/committee`)
}

const subscriptionPlans = () => {
  return http.get(`/subscriptionplan`)
}

const getGroupCodes = (config =null) => {
    
  return http.get("/user/groupcode", config);
};

const getTaxCodes = (config =null) => {
  
  return http.get("/user/taxgroup", config);
};

const updatepassword = (data, config=null )=> {
  return http.post("/updatePassword", data, config)
}
const profilePicture = (data, config =null) => {

  return http.post("/profilepicture", data, config)
}
const memberUser = (id) => {
  return http.get(`user/member/${id}`)
}

const roles = () => {
  return http.get('user/roles')
}

const getCart = (config = null) => {
  return http.get('cart', config)
}

const removeCart = (id, config = null) => {
  return http.delete(`/cart/remove-from-cart/${id}`, config)
}


export default {
  getUsers,
  memberUser,
  get,
  login,
  signup,
  forgot,
  roles,
  reset,
  allProducts,
  OrderItem,
  sendBroadcast,
  allOrders,
  confirmedOrder,
  totalAmountSpent,
  deliverOrder,
  allHallOrders,
  deliverHallOrder,
  allPresident,
  postPresident,
  allTrustees,
  allCommittee,
  subscriptionPlans,
  getGroupCodes,
  getTaxCodes,
  updatepassword,
  profilePicture,
  addToCart,
  getCart,
  removeCart
};