import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './sidenav'
import DataService from "./services/Events";
import DatePicker from "react-datepicker";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
// import FlashMessage from 'react-flash-message';
// from 'react-toastify'
export default function Holiday() {

	const [isError, setIsError] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	const [isSucess, setIsSucess] = useState(false);
	const [message, setMessage] = useState(false);
    const [holiday, setHoliday] = useState([]);

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");


	const [name, setName] = useState("");
	const [title, setTitle] = useState("");
    const [startYear, setStartYear] = useState(new Date());
    const [endYear, setEndYear] = useState(new Date());
    const [isDeceased, setIsDeceased] = useState('0');


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  
        


    useEffect(() => {
        
        getHoliday();

      }, []);

      const config = {
        headers: {
          Authorization : 'Bearer '+ localStorage.getItem('token')
        }
      }

      const getHoliday =() => {

        DataService.calendar(config)
        .then(result => {

          if (result.status === 200) {
            setHoliday(result.data.data);
          } else {
          setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
      }

    const deleteHoliday = (id) => {

		DataService.removeCalendar(id, config)
		.then(result => {
			if (result.status === 200) {

                window.location.reload()

				setIsSucess(true);

			} else {
			setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}




    const renderHeader = () => {
        let headerElement = ['id', 'name', 'date', 'Action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return holiday && holiday.map(({ id, title, start, is_holiday }) => {
            return (
                <tr key={id}>
                    <td>{id}</td>
                    <td>{title}</td>
                    <td>{start}</td> 
                    <td className='operation'>
                        {
						 <button style={{ margin:"10px"  }} className="btn btn-sm btn-danger " onClick={() => deleteHoliday(id)}> Remove </button> 
						}
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>

        <div className="dashboard-container">

    <Sidenav />

    <div className="dashboard-content-container" data-simplebar>
    <div className="dashboard-content-inner" >
        
        <div className="dashboard-headline">
            <h3><i className="icon-line-awesome-users"></i> Holidays</h3>

            <nav id="breadcrumbs" className="dark">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>Holidays</li>
                </ul>
            </nav>
        </div>

        <div className="row">

            <div className="col-xl-12">
                <div className="dashboard-box margin-top-0">

                    <div className="headline">


                        <div>
                            <h3><i className="icon-feather-folder-plus"></i> Holidays</h3>

                        </div>
                    </div>

                    <div className="previewProfilePic">
              </div>
    
              <Modal open={open} onClose={onCloseModal} center>
                            <h2>Holidays</h2> <br />
                            <div className="content with-padding padding-bottom-10">

                            { isSucess && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
								</div>)
						    }

                            { isError && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
								</div>)
						    }

                            <LoadingOverlay
                                active={disabled}
                                spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                                text='Loading...'
                            >
                            </LoadingOverlay>


                    </div>
                        </Modal>
                        <div className="content with-padding padding-bottom-10">
                        <>
                        <h1 align="center"> List Of Holidays</h1>

								<table id='employee'>
									<thead>
										<tr>{renderHeader()}</tr>
									</thead>
									<tbody>{renderBody()}</tbody>
								</table>
							</>
                        </div>

                </div>
            </div>




        </div>

        <DashboardFooter />

    </div>
</div>
        </div>
    </div>
    )
}
