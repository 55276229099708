import http from "./common/http-common";

  const user = (config =null) => {
    
    return http.get("/user/show", config);
  };

  const searchUsers = (config =null) => {
    return http.post("search/user", config)
  }

  const viewUser = (id) => {
    return http.get(`viewUser/${id}`)
  }

  const getCurrentUser = () => {
    const config = {
      headers: {
        Authorization : 'Bearer '+ localStorage.getItem('token')
      }
    }
    return http.get(`/user`, config)
  };
  
  const getUserMetrics = (config =null) => {
    
    return http.get("/metrics", config);
  };
    
  const getStatement = (data = null, config = null) => {

    return http.post("/users/statement", data, config);
  };

  const getCustomerByNumber = (data, config=null) => {

    return http.get(`/customer/${data}`, config);
  };


  export default {
    user,
    searchUsers,
    viewUser,
    getCurrentUser,
    getUserMetrics,
    getStatement,
    getCustomerByNumber,
  };