import React, {useMemo, useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DataService from "./services/User";
import {
	Container,
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import StatementContainer from "./statementcontainer";
import { SelectColumnFilter } from './filters';
import DatePicker from "react-datepicker";



export default function Statement() {

	const [statement, setStatement] = useState([]);
	const [customerNumber, setCustomerNumber] = useState([]);
	const [customer, setCustomer] = useState([]);
	const [sumBalance, setSumBalance] = useState([]);
	const [openingBalance, setOpeningBalance] = useState([]);
	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState(false);
	const [validateMessage, setValidateMessage] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
	const [user, setUser] = useState([]);

	useEffect(() => {    

		getCurrentUser();
		loadStatment();
		
      }, []);




	  const config = {
		headers: {
			Authorization : 'Bearer '+ localStorage.getItem('token')
		}
	}

	const getCurrentUser = () => {
		DataService.user(config)
		.then(result => {
		if (result.status == 200) {

			setUser(result.data.data);
			// setIsSignup(true);
		} 
		else {
			setIsError(true);
		}
		}).catch(e => {
		setIsError(true);
		});

	}

		// customers statment 
	const loadStatment = () => {
		var data = {
			start_date: '',
			end_date: '',
		  }

				DataService.getStatement(data, config)
				.then(result => {
				if (result.status === 200) {
					setSumBalance(result.data.data)
					setOpeningBalance(result.data.data.openingBalance)
					setCustomerNumber(result.data.data.CustomerStatement[0].CustomerNumber);
					getcustomer(result.data.data.CustomerStatement[0].CustomerNumber);
					setStatement(result.data.data.CustomerStatement)
				} 

			});
	}

	// customers number
	const getcustomer = (number) => {

			DataService.getCustomerByNumber(number, config)
			.then(result => {

			if (result.status === 200) {

				setCustomer(result.data.data)
			} 

				}).catch(e => {

			});
		};

	//   statement submission
	const submitStatement = (e) => {
        e.preventDefault();

        var data = {
			start_date: convertDateToString(startDate),
			end_date: convertDateToString(endDate),
        }

        setDisasbled(true);

      DataService.getStatement(data, config)
	  .then(result => {
        if (result.status === 200) {

			setSumBalance(result.data.data)
			setCustomerNumber(result.data.data.CustomerStatement[0].CustomerNumber);
			getcustomer(result.data.data.CustomerStatement[0].CustomerNumber);
			setStatement(result.data.data.CustomerStatement)
			setOpeningBalance(result.data.data.openingBalance)
			setMessage(result.data.message)

			setIsSucess(true);

			setIsError(false);

			setDisasbled(false)

		} 
		else {
			setMessage(result.data.message)

			setIsError(true);
			setDisasbled(false)

		}
	}).catch(e => {

		setMessage(e.response.data.message)
		setValidateMessage(e.response.data)
		setIsError(true);
		setDisasbled(false)

	});
	  }

	  const convertDateToString = (date) => 
	  {
		var result = new Date(date);

		var tzo = -result.getTimezoneOffset(),
		dif = tzo >= 0 ? '+' : '-',
		pad = function(num) {
			var norm = Math.floor(Math.abs(num));
			return (norm < 10 ? '0' : '') + norm;
		};

	return result.getFullYear() +
		'-' + pad(result.getMonth() + 1) +
		'-' + pad(result.getDate()) +
		'T' + pad(result.getHours()) +
		':' + pad(result.getMinutes()) +
		':' + pad(result.getSeconds()) + 'Z'

	  }

	  const CustomTimeInput = ({ date, value, onChange }) => (
		<input
		  value={value}
		  onChange={(e) => onChange(e.target.value)}
		  style={{ border: "solid 1px pink" }}
		/>
	  );

	



	  const formatNumber = (number) => {

		let twoDecimalPlaces = (Math.round(number * 100) / 100).toFixed(2);

		return twoDecimalPlaces.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const convertTwoDecimal = (number) => {
		if (number == undefined)
		{
			return 0
		}
		return (Math.round(number * 100) / 100).toFixed(2);;

	}

	const greetCustomers = () => {

		var d = new Date();

		var time = d.getHours();

		if (time < 12) {

			return (<b> Good Morning </b>);
		}
		else if (time >= 12 && time <= 17) {

			return (<b> Good Afternoon </b>)
		}

		else if (time >= 17 && time <= 24) {

			return (<b> Good Evening </b>)
		}
	}

	const columns = useMemo( () => [
			{
				Header: "Date",
				accessor: (values) => {

					return new Date(values.PostingDate).toLocaleDateString();
					},
				disableFilters: true,
			},
			{
				// Header: "Number",
				// columns: [
				// 		{
				// 			Header: "Document Number",
				// 			accessor: "DocumentNumber",
				// 			disableSortBy: true,
				// 			filter: 'equals',
				// 		}
				// 	],

				Header: "Document Number",
				accessor: "DocumentNumber",
				filter: 'equals',
				disableFilters: true,

			},
			{
				Header: "Description",
				accessor: "DocumentDescription",
				filter: 'equals',
				disableFilters: true
			},
			{
				Header: "Debit",
				accessor: (values) => {
						if(values.DocumentType == "Invoice" || values.DocumentType == "DebitNote" || values.DocumentType == "Refund" || values.DocumentType == "InterestCharge" ){
				
							var amount = formatNumber(values.FunctionalCurrencyInvoiceAmount);
							
							return  amount ;
						}
						else{
		
							return  '-' ;
						}
					},
					disableFilters: true
				},
			{
				Header: "Credit",
				accessor: (values) => {
					if(values.DocumentType == "CreditNote" || values.DocumentType == "Receipt" || values.DocumentType == "UnappliedCash" || values.DocumentType == "Prepayment"){

						var amount = formatNumber(values.FunctionalCurrencyInvoiceAmount);
							return  (amount) ;
						}
						else{
							return   '-' ;
						}
					},
				disableFilters: true
			},		
			{
				Header: "Closing balance",
				accessor: (values) => {
					return formatNumber(values.closingBalance)
					},
				disableFilters: true

			},
			{
				Header: "Status",
				accessor: (values) => {
						if(values.DocumentType == "Invoice" || values.DocumentType == "DebitNote"){
									
							return  "Dr" ;
						}
						else{
		
							return  'Cr' ;
						}
					},
					disableFilters: true
			},	
			// {
			// 	Header: "Group Code",
			// 	accessor: "GroupCode",
			// 	filter: 'equals',
			// 	Filter: SelectColumnFilter,
			// },
		],
		[]
	  )

      
      return (
        <div>
            <div className="dashboard-container">

	<Sidenav />
            <div className="dashboard-content-container" data-simplebar>
		<div className="dashboard-content-inner" >
			
			<div className="dashboard-headline">
				<h3>Statement</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Statement</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">

				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-material-outline-account-balance"></i> Statement</h3>
							<h4 style={{ float: "right" }}> {greetCustomers()} { user.firstname }</h4>
						</div>

						<div className="content with-padding padding-bottom-0">
							<div align="center" id="formatStatement"> 

								<h2 id="statementHeader"> THE METROPOLITAN CLUB </h2> <br />
								<p id="statementTitle" style={{ textDecoration: "underline" }}>  MEMBERS STATEMENT OF ACCOUNT </p>
								<p id="custNumber"> Customer Number : {user.member_number}</p>
								<div> Customer Name: <p id="custName"> {user.firstname + ' ' + user.lastname} </p> </div>
								<div id="openingBalance"> 
									Opening Balance: {openingBalance}
								</div>

							</div>


							<Container style={{ marginTop: 70 }}>
								<form onSubmit={submitStatement}>
									<div style={{float: "right"}}>
										<span style={{display:"inline-block"}}>
											<DatePicker
												selected={startDate}
												onChange={(date) => setStartDate(date)}
												selectsStart
												startDate={startDate}
												endDate={endDate}
												dateFormat="yyyy-MM-dd"
												showTimeInput
												isClearable
												placeholderText="start date"
											/>
										</span>

										<span style={{display:"inline-block"}}>
											<DatePicker
												selected={endDate}
												onChange={(date) => setEndDate(date)}
												selectsEnd
												startDate={startDate}
												endDate={endDate}
												minDate={startDate}
												dateFormat="yyyy-MM-dd"
												showTimeInput
												isClearable
												placeholderText="end date"
											/>
										</span>
											<span style={{display:"inline-block", marginLeft: "5px"}}>
												<button type="submit" className="btn btn-success ripple-effect" disabled={disabled} ><i className="icon-feather-search"></i> Submit  </button>
											</span>
									</div>
								</form>

								<StatementContainer
									openingbalance={openingBalance}
									columns={columns}
									data={statement}
								/>
								
								{statement.length == 0 ? (<div align="center"> <img src="/assets/images/no_data1.gif" /> </div>) : ""}

							</Container>
							<br />
							<div className="row">
								<div className="col-md-3">
									<div className="card">
										<div className="container">
										<h1><i className="icon-material-outline-monetization-on"></i> </h1> 
											<h5><b>Total Credit</b></h5> 
											<p> {convertTwoDecimal(sumBalance.CreditSum)} </p> 
										</div>
										</div>
								</div>

								<div className="col-md-3">
								<div className="card">
										<div className="container">
										<h1><i className="icon-material-outline-monetization-on"></i> </h1> 
											<h5><b>Total Debit</b></h5> 
											<p> {convertTwoDecimal(sumBalance.DebitSum)} </p> 
										</div>
										</div>
								</div>
								<div className="col-md-3">
								<div className="card">
										<div className="container">
										<h1><i className="icon-material-outline-monetization-on"></i> </h1> 
											<h5><b>Opening Balance</b></h5> 
											<p> {convertTwoDecimal(openingBalance)} </p> 
										</div>
										</div>
								</div>
								<div className="col-md-3">
								<div className="card">
										<div className="container">
										<h1><i className="icon-material-outline-monetization-on"></i> </h1> 
											<h5><b>Balance</b></h5> 
											<p> {convertTwoDecimal(sumBalance.RunningBalance)} </p> 
										</div>
										</div>

								</div>

						</div>
					</div>
						<br />
					</div>
				</div>
				
                </div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}