import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import Footer from './dashboardfooter'
import Dataservice from '../dashboard/services/User';



export default function SingleUser(props) {


	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isUser, setIsUser] = useState([]);

	let user_id= props.match.params.id;

 
    useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    

		getUser(user_id);

      }, []);



	const getUser= (data) => {

		Dataservice.viewUser(data)
		.then(result => {

			if (result.status === 200) {
				setIsUser(result.data.data);
				} else {
				setIsError(true);
				}
			}).catch(e => {
				setIsError(true);
		});
		}

		// let handwork= isUser.artisan_information && isUser.artisan_information.map(data => data.handwork );

		
		const handworker = () => {
			let verified = (<div className="verified-badge-with-title">Verified</div>);

			return (
				<div className="col-md-12">
				<div className="single-page-header-inner">
					<div className="left-side">
						{/* <div className="header-image freelancer-avatar"><img src="/assets/images/user-avatar-placeholder.jpeg" alt="" /></div> */}
						{/* <div className="header-image freelancer-avatar"> {isUser.profile_picture && isUser.profile_picture.map((data, index) => data.picture_url ?  <img key={index} src={data.picture_url} alt="" /> : <img src="/assets/images/user-avatar-placeholder.jpeg" alt="" /> )} </div> */}
						<div className="header-image freelancer-avatar"> {isUser.image ?  <img src={isUser.image} alt="" /> : <img src="/assets/images/user-avatar-placeholder.jpeg" alt="" /> } </div>
						<div className="header-details">
							{/* <h3>{isUser.email} <span>Tiler</span></h3> */}
							{/* <h3>{isArtisan.artisan_information && isArtisan.artisan_information.map((data, index) => data.firstname + ' ' + data.lastname )} <span > {handwork} </span></h3> */}
							<h3>Fullname: {isUser.firstname + ' ' + isUser.lastname } </h3> <br />
							<h3>Email: {isUser.email } </h3> <br />
							<h3>UserId: {isUser.username } </h3> <br />
							<h3>Date Of Birth: {isUser.date_of_birth } </h3> <br />
							<h3>Gender: {isUser.gender } </h3> <br />
							<h3>Address: {isUser.address } </h3> <br />

							<ul>
								{/* <li><div className="star-rating" data-rating="5.0"></div></li> */}
								<li><img className="flag" src="/assets/dashboard/images/flags/ng.svg" alt="" /> {isUser.state + " "+ isUser.city}</li>
								<li> {isUser.is_verified ? verified : ''}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			)
		}

      return (
        <div>
            <div className="single-page-header freelancer-header" data-background-image="/assets/dashboard/images/single-freelancer.jpg">
	<div className="container">
		<div className="row">
			{handworker()}
		</div>
	</div>
</div>


<div className="container">
	<div className="row">
		
		{/* <!-- Content --> */}
		<div className="col-xl-8 col-lg-8 content-right-offset">
			
			{/* <!-- Page Content --> */}
			<div className="single-page-section">
				<h3 className="margin-bottom-25">About Me</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

				<br />

				{
					isUser.is_blocked ? (<button className="btn btn-primary"> UnBlock User</button>) : ( <button className="btn btn-primary"> Block User</button>)
				}
			</div>

		</div>
		

		{/* <!-- Sidebar --> */}
		<div className="col-xl-4 col-lg-4">
			<div className="sidebar-container">
				
				<div className="profile-overview">
					{/* <div className="overview-item"><strong>53</strong><span>Jobs Done</span></div>
					<div className="overview-item"><strong>22</strong><span>Rehired</span></div> */}
				</div>


				{/* <Link to={`/confirm_offer/${isArtisan.id}`} > 
					<button  className="button big ripple-effect margin-top-40 margin-bottom-65"> Make an Offer <i className="icon-material-outline-arrow-right-alt"></i> </button>
				</Link> */}

				
				<div className="sidebar-widget">
					<h3>Social Profiles</h3>
					<div className="freelancer-socials margin-top-25">
						<ul>
                            <li><a href="#" title="Facebook" data-tippy-placement="top"> <i className="icon-brand-facebook-f"></i> </a></li>
                            <li><a href="#" title="Twitter" data-tippy-placement="top"> <i className="icon-brand-twitter"></i> </a></li>
                            <li><a href="#" title="Google Plus" data-tippy-placement="top"> <i className="icon-brand-google-plus-g"></i> </a></li>
                            <li><a href="#" title="LinkedIn" data-tippy-placement="top"> <i className="icon-brand-linkedin-in"></i> </a></li>
						
						</ul>
					</div>
				</div>

				<div className="sidebar-widget">
					<h3>Skills</h3>
					<div className="task-tags">
						{/* <span>{handwork}</span> */}
					</div>
				</div>



			</div>
		</div>

	</div>
</div>


<div className="margin-top-15"></div>
            <Footer />




<div id="small-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs">

	<div className="sign-in-form">

		<ul className="popup-tabs-nav">
			<li><a href="#tab">Make an Offer</a></li>
		</ul>

		<div className="popup-tabs-container">

			{/* <!-- Tab --> */}
			<div className="popup-tab-content" id="tab">
				
				{/* <!-- Welcome Text --> */}
				<div className="welcome-text">
					<h3>Discuss your project with David</h3>
				</div>
					
				{/* <!-- Form --> */}
				<form method="post">

					<div className="input-with-icon-left">
						<i className="icon-material-outline-account-circle"></i>
						<input type="text" className="input-text with-border" name="name" id="name" placeholder="First and Last Name"/>
					</div>

					<div className="input-with-icon-left">
						<i className="icon-material-baseline-mail-outline"></i>
						<input type="text" className="input-text with-border" name="emailaddress" id="emailaddress" placeholder="Email Address"/>
					</div>

					<textarea name="textarea" cols="10" placeholder="Message" className="with-border"></textarea>

					<div className="uploadButton margin-top-25">
						<input className="uploadButton-input" type="file" accept="image/*, application/pdf" id="upload" multiple/>
						<label className="uploadButton-button ripple-effect" htmlFor="upload">Add Attachments</label>
						<span className="uploadButton-file-name">Allowed file types: zip, pdf, png, jpg <br /> Max. files size: 50 MB.</span>
					</div>

				</form>
				
				<button className="button margin-top-35 full-width button-sliding-icon ripple-effect" type="submit">Make an Offer <i className="icon-material-outline-arrow-right-alt"></i></button>

			</div>			

		</div>
	</div>
</div>

        </div>
    )
}
