import React from 'react'

export default function Team() {
    return (
        <div>
            <section className="team-page-section centred">
              <div className="auto-container">
                  <div className="row clearfix">
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/sir_adetokunboh_ademola.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3> SIR ADETOKUNBO ADEMOLA GCON, GCFR, KT PC</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/sir_louis_nwachukwu.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>SIR LOUIS MBANEFO</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>MR. ROBERT FLEMMING</h3>
                                      <span className="designation">Artist</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>SIR LEONARD DALDRY</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>DR. C.M. NORMAN-WILLIAMS</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>DR. DELE ALAKIJA</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/charles_dadi_onyeama.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>MR. JUSTICE CHARLES D. ONYEAMA</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>CHIEF JOE HAROLD</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 team-block">
                          <div className="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                              <div className="inner-box">
                                  <figure className="image-box">
                                      <img src="/assets/images/board/person-placeholder.png" className="img-fluid foundingfathers" alt="" />
                                      
                                  </figure>
                                  <div className="lower-content">
                                      <h3>MR. E.C. JUDD</h3>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </section>
        </div>
    )
}
