import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './sidenav'
import DataService from "./services/Events";
import { Card, Logo, Form, Success, Input, Button, Error } from "../dashboard/AuthForms";
import DatePicker from "react-datepicker";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
// import FlashMessage from 'react-flash-message';
// from 'react-toastify'
export default function AddPresident() {

	const [isError, setIsError] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	const [isSucess, setIsSucess] = useState(false);
	const [message, setMessage] = useState(false);

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");


	const [name, setName] = useState("");
	const [title, setTitle] = useState("");
    const [startYear, setStartYear] = useState(new Date());
    const [endYear, setEndYear] = useState(new Date());
    const [isDeceased, setIsDeceased] = useState('0');
    const [president, setPresident] = useState([]);


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
  
        


    useEffect(() => {
        
        getPresidents();

      }, []);


      const getPresidents =() => {

        DataService.getPresident()
		.then(result => {

			if (result.status === 200) {

				setPresident(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }


    const postPresident = (e) => {
		e.preventDefault();

		var data = {
            name,
            title,
            is_deceased : isDeceased,
            year_from : startYear,
            year_to : endYear,
		}

        setDisasbled(true);

        DataService.addPresident(data)
		.then(result => {

            if (result.status === 201) {			

                setMessage(result.data.message)

                setIsSucess(true);
                                                
                setDisasbled(false)                
                
                toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));
            } 
            else {
                setMessage(result.data.message)
                setIsError(true);
                setDisasbled(false)
                toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

            }
		}).catch(e => {

            setMessage(e.response.data.message)
            setDisasbled(false)
		    setIsError(true);
            toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))

		});


	  }


	const deceased = (id) => {
		DataService.deceasedPresident(id)
		.then(result => {
			if (result.status === 200) {

                window.location.reload();

				setIsSucess(true);
			} else {
			setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
		
	}

    const notDeceased = (id) => {

		DataService.notDeceasedPresident(id)
		.then(result => {
			if (result.status === 200) {

                window.location.reload()

				setIsSucess(true);

			} else {
			setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}


    const deletePresident = (id) => {

		DataService.destroyPresident(id)
		.then(result => {
			if (result.status === 200) {

                window.location.reload()

				setIsSucess(true);

			} else {
			setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}

    const firstFourCharacter= (str) => 
    {
      if (str == null)
      {
          return 'till date'; 
      }
      return str.substring(0,4);
    }


    const renderHeader = () => {
        let headerElement = ['id', 'name', 'title', 'is_deceased', 'year from - year to', 'operation']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return president && president.map(({ id, name, title,year_from, year_to, is_deceased }) => {
            return (
                <tr key={id}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>{title}</td> 
                    <td>{is_deceased ? <p style={{ color:"red"  }}> *Deceased </p> : ''}</td>
                    <td>{firstFourCharacter(year_from)} - {firstFourCharacter(year_to)}</td>
                    <td className='operation'>
						{
						    is_deceased ? (<button style={{ margin:"10px"  }} className='btn btn-sm btn-success ' onClick={() => notDeceased(id)}> Not Deceased </button>) : ( <button style={{ margin:"10px"  }} className="btn btn-danger" onClick={() => deceased(id)}> Make Deceased </button> )
						}
                        {
						 <button style={{ margin:"10px"  }} className="btn btn-sm btn-danger " onClick={() => deletePresident(id)}> Delete </button> 
						}
                    </td>
                </tr>
            )
        })
    }

    return (
        <div>

        <div className="dashboard-container">

    <Sidenav />

    <div className="dashboard-content-container" data-simplebar>
    <div className="dashboard-content-inner" >
        
        <div className="dashboard-headline">
            <h3><i className="icon-line-awesome-users"></i> Add Club Presidents</h3>

            <nav id="breadcrumbs" className="dark">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li>Add Club Presidents</li>
                </ul>
            </nav>
        </div>

        <div className="row">

            <div className="col-xl-12">
                <div className="dashboard-box margin-top-0">

                    <div className="headline">


                        <div>
                            <h3><i className="icon-feather-folder-plus"></i> Add Club Presidents</h3>

                            <div style={{ float: 'right' }}> <button className="btn btn-primary btn-sm" onClick={onOpenModal}> <i className="icon-feather-plus"></i> Add Club Presidents</button> </div>

                        </div>
                    </div>

                    <div className="previewProfilePic">
              </div>
    
              <Modal open={open} onClose={onCloseModal} center>
                            <h2>Add Club Presidents</h2> <br />
                            <div className="content with-padding padding-bottom-10">

                            { isSucess && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
								</div>)
						    }

                            { isError && (<div>
                                <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
								</div>)
						    }

                            <LoadingOverlay
                                active={disabled}
                                spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                                text='Loading...'
                            >
                            </LoadingOverlay>

                        <form onSubmit={postPresident}>
                        <div className="row">

                            <div className="col-xl-6">
                                <div className="submit-field">
                                    <h5>President Name</h5>
                                    <input type="text" name="name"  value={name} onChange={e => {setName(e.target.value);}} className="with-border" required />
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="submit-field">
                                    <h5>Title</h5>
                                        <div className="input-with-icon">
                                            <input name="title"  value={title} onChange={e => {setTitle(e.target.value);}} className="with-border" type="text" placeholder="Title"  />
                                        </div>
                                </div>
                            </div> 

                            <div className="col-xl-6">
                                <div className="submit-field">
                                    <h5>Year From</h5>
                                    <DatePicker  dateFormat="yyyy-MM-dd" selected={startYear} onChange={date => setStartYear(date)} />
                                </div>
							</div>

                            <div className="col-xl-6">
                                <div className="submit-field">
                                    <h5>Year To</h5>
                                    <DatePicker  dateFormat="yyyy-MM-dd" selected={endYear} onChange={date => setEndYear(date)} />
                                </div>
							</div>
                            </div>

                            <div className="col-xl-6">
								<div className="submit-field">
											<h5>Is President Deceased</h5>
                                    <div className="input-with-icon">
                                        <select value={isDeceased} onChange={e => {setIsDeceased(e.target.value);}}>
										<option value='1'> Yes </option>
										<option value='0'> No </option>
                                        </select>
                                        <i className="icon-feather-users"></i>
                                    </div>
                                </div>
                        </div>

                            <div className="col-xl-12">
                                <button type="submit" className="btn btn-success btn-lg ripple-effect big margin-top-30"  disabled={disabled} ><i className="icon-feather-plus"></i> Add President</button>
                            </div>
                        </form>

                    </div>
                        </Modal>
                        <div className="content with-padding padding-bottom-10">
                        <>
                        <h1 align="center"> List Of Club Presidents</h1>

								<table id='employee'>
									<thead>
										<tr>{renderHeader()}</tr>
									</thead>
									<tbody>{renderBody()}</tbody>
								</table>
							</>
                        </div>

                </div>
            </div>




        </div>

        <DashboardFooter />

    </div>
</div>
        </div>
    </div>
    )
}
