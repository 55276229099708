import React, {useEffect, useState} from 'react';
import { Link, Redirect } from 'react-router-dom';
import DataService from "./services/Service";
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { Card, Logo, Form, Success, Input, Button, Error } from "./AuthForms";



export default function PasswordUpdate() {

	const [isError, setIsError] = useState(false);
	const [password, setPassword] = useState("");
	const [oldpassword, setOldPassword] = useState("");
	const [confirm_password, setConfirmPassword] = useState("");
	const [message, setMessage] = useState('');
	const [isSucess, setIsSucess] = useState(false);
	const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");

	  const postPassword = (e) => {
		e.preventDefault();

		var data = {
			password,
			oldpassword,
			confirm_password,
		}

		const config = {
			headers: {
				Authorization : 'Bearer '+ localStorage.getItem('token')
			}
		}

		setDisasbled(true);

		DataService.updatepassword(data, config)
		.then(result => {

			if (result.status === 200) {

			setMessage(result.data.message)

			setIsSucess(true);

			setDisasbled(false)

			} 
			else {
			setMessage(result.data.message)

			setIsError(true);

			setDisasbled(false)
		  }
		}).catch(e => {
			setMessage(e.response.data.message)
            
			setDisasbled(false)

		  	setIsError(true);
		});

	  }


    return (
        <div>

				{isSucess && <Success>{message}</Success>}

				{ isError &&<Error> {message}</Error> }

				<LoadingOverlay
					active={disabled}
					spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
					text='Loading...'
				>
				</LoadingOverlay>

            
            <form onSubmit={postPassword}>

					<div id="test1" className="dashboard-box">

						<div className="headline">
							<h3><i className="icon-material-outline-lock"></i> Password & Security</h3>
						</div>

						<div className="content with-padding">
							<div className="row">
								<div className="col-xl-4">
									<div className="submit-field">
										<h5>Current Password</h5>
										<input type="password"  value={oldpassword} onChange={e => {setOldPassword(e.target.value);}} className="with-border" />
									</div>
								</div>

								<div className="col-xl-4">
									<div className="submit-field">
										<h5>New Password</h5>
										<input type="password" value={password} onChange={e => {setPassword(e.target.value);}} className="with-border" />
									</div>
								</div>

								<div className="col-xl-4">
									<div className="submit-field">
										<h5>Repeat New Password</h5>
										<input type="password" value={confirm_password} onChange={e => {setConfirmPassword(e.target.value);}} className="with-border" />
									</div>
								</div>

								</div>
							</div>

                            <div className="col-xl-12">
                                    <button type="submit" className="btn btn-success" >Update Password</button>
                            </div>
                        
                                <br />
						
                        </div>

					</form>
        </div>
    )
}
