import React from 'react'

export default function Slider() {
  return (
	<div>
		{/* <div id="carousel-example-generic" className="carousel slide" data-interval="3500" data-ride="carousel" data-pause="hover">
				<ol className="carousel-indicators">
					<li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
					<li data-target="#carousel-example-generic" data-slide-to="1"></li>
					<li data-target="#carousel-example-generic" data-slide-to="2"></li>
					<li data-target="#carousel-example-generic" data-slide-to="3"></li>
					<li data-target="#carousel-example-generic" data-slide-to="4"></li>
					<li data-target="#carousel-example-generic" data-slide-to="5"></li>
				</ol>
				
				<div className="carousel-inner" role="listbox" >
					<div className="item active ">
						<img src="/assets/images/slider/Home/1.JPG" alt="first slide" />
					</div>
					
					<div className="item ">
						<img src="/assets/images/slider/Home/2.JPG" alt="first slide" />
					</div>
					<div className="item">
						<img src="/assets/images/slider/Home/3.JPG" alt="Second slide" />
					</div>
					<div className="item">
						<img src="/assets/images/slider/Home/4.JPG" alt="Third slide" />
					</div>

					<div className="item">
						<img src="/assets/images/slider/Home/5.JPG" alt="Fourth slide" />
					</div>
					<div className="item">
						<img src="/assets/images/slider/Home/6.JPG" alt="Fifth slide" />
					</div>
				</div>
				<a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
					<span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
				</a>
				<a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
					<span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
				</a>
			</div> */}
			<div id="carouselExampleIndicators" className="carousel slide" data-interval="3500" data-ride="carousel" data-pause="hover">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
  </ol>
  <div className="carousel-inner" role="listbox">
    <div className="carousel-item active" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/1.JPG" alt="First slide" />
    </div>
	<div className="carousel-item" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/2.JPG" alt="Second slide" />
    </div>
	<div className="carousel-item" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/3.JPG" alt="Third slide" />
    </div>
    <div className="carousel-item" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/4.JPG" alt="Forth slide" />
    </div>
    <div className="carousel-item" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/5.JPG" alt="Fifth slide" />
    </div>
	<div className="carousel-item" role="option">
      <img className="d-block img-fluid" src="/assets/images/slider/Home/6.JPG" alt="Sixth slide" />
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
	</div>
  )
}
