import axios from "axios";
import CONFIG from "../../../../config/config";

export default axios.create({
  
  baseURL: 'http://127.0.0.1:8000/api', //CONFIG.API_BASE_URL,
  headers: {
    "Content-type": "application/json",
  }
});
