import React, {useEffect, useState} from 'react'
import Footer from './footer'
import Header from './header'
import {Link} from 'react-router-dom';
import DataService from "../dashboard/services/Service";


export default function Club_Trustees() {

	const [trustees, setTrustees] = useState([]);
    const [isError, setIsError] = useState(false);


    useEffect(() => {
 
      getTrustee();

      }, []);

      const getTrustee =() => {
        DataService.allTrustees()
        .then(result => {

          if (result.status === 200) {
              setTrustees(result.data.data);
          } else {
            setIsError(true);
          }
        }).catch(e => {
          setIsError(true);
        });
      }
      
      const firstFourCharacter= (str) => 
      {
        // var str = '012123'
        if (str == null)
        {
            return 'till date'; 
        }
        return str.substring(0,4);
      }


      const renderHeader = () => {
        let headerElement = [ 'name', 'title', 'Year', ]

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return trustees && trustees.map(({ id, name, title, is_deceased }) => {
            return (
                <tr key={id} style={{ color: 'white' }}>
                    <td>{name} {is_deceased ? '*' : ''}</td>
                    <td>{title}</td> 
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
        })
    }

    return (

        <div>

    <section className="history-section" style={{ background: '#3f0d12' }} >
        <div className="auto-container">
          <div align= 'center'><img src="/assets/images/background/metclub_eblem.png" alt='' /> </div>

          <div className="sec-title">
              <h2 style={{ color:'white' }}> TRUSTEES </h2>
              <div className="separator"></div>
          </div>

          <div className="centerborderless">
            <div className="row clearfix"> 
              <>
                  <table className="table center table-responsive borderless">
                    <thead>
                    </thead>
                    <tbody>{renderBody()}</tbody>
                  </table>
              </>
              <p>
                * Deceased
              </p>
            </div> 
          </div>

        </div> 
    </section>
  <Footer />
</div>
    )
}
