import React, {useEffect, useState} from 'react'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DataService from "../dashboard/services/Service";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";

export default function Cart() {


    const [cart, setCart] = useState([]);
    const [isError, setIsError] = useState('');
    const [message, setMessage] = useState(false);
	const [isSucess, setIsSucess] = useState(false);
    const [disabled, setDisasbled] = useState(false)
	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");

    const config = {
        headers: {
            Authorization : 'Bearer '+ localStorage.getItem('token')
        }
    }

    useEffect(() => {
		getCart();
      }, []);

      const getCart = () => {
        DataService.getCart(config)
		.then(result => {
			if (result.status === 200) {
				setCart(result.data.data);
		  } else {
			setIsError(true);
		  }
		}).catch(e => {
		  setIsError(true);
		});
      }

      const removeCart = (id) => {

        DataService.removeCart(id, config)
        .then( result => {
            if (result.status === 200) {
				setMessage(result.data.message)

				setIsSucess(true);
	 
				setIsError(false);
	 
				setDisasbled(false)

				toast.success((<p style={{ fontSize:"14px" }}> {result.data.message}</p>));
                
                getCart()
			   } else {
	 
				 setMessage(result.data.message)

				 setIsError(true);
	 
				 setDisasbled(false)
				 toast.error((<p style={{ fontSize:"14px" }}> {result.data.message}</p>))

                 getCart()

			   }
			 }).catch(e => {
	 
				setMessage(e.response.data.message)

				setIsError(true);
		
				setDisasbled(false)
				toast.error((<p style={{ fontSize:"14px" }}> {e.response.data.message}</p>))
		});
    }

      const cartList = () => {
        return cart && cart.map(({ id, amount, product }) => {
            return (
                <tr key={id}>
                    <td colSpan="4" className="prod-column">
                        <div className="column-box">
                            <div className="remove-btn" onClick={() => removeCart(id)} disabled={disabled}>
                                <i className="flaticon-close"></i>
                            </div>
                            <div className="prod-thumb">
                                <a href="#"><img src={product.image} style={{ height:"100px", width:"125px" }} alt="" /></a>
                            </div>
                            <div className="prod-title">
                                {product.name}
                            </div>    
                        </div>
                    </td>
                    <td className="price"> &#8358; {amount}</td>
                    <td className="qty">
                        <div className="item-quantity">
                            <input className="quantity-spinner" type="text" value="1" name="quantity" />
                        </div>
                    </td>
                    <td className="sub-total">&#8358; {amount}</td>
                </tr>
			)
        })
    }

    const cartTotalAmount = () => {
        let amount = 0;

        cart && cart.forEach(element => {
            amount += element.amount
        });

        return amount;
    } 

    const noCartData = () => {
        return (
            <tr>
                <td></td>
                <td>
                    <div className="container-fluid  mt-100">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Cart</h5>
                                    </div>
                                    <div className="card-body cart">
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src="https://i.imgur.com/dCdflKN.png" width="130px" height="130px" className="img-fluid mb-4 mr-3" alt='' />
                                            <h3><strong>Your Cart is Empty</strong></h3>
                                            {/* <h4>Add something to make me happy :)</h4> */}
                                            <Link to="/ecommerce" className="btn btn-primary cart-btn-transform m-3" data-abc="true">continue shopping</Link>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>   
                </td>
            </tr> )
        }

  return (
    <div>
        <section className="cart-section cart-page">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 table-column">
                        <div className="table-outer">
                            <table className="cart-table">
                                <thead className="cart-header">
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th className="prod-column">Product Name</th>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                        <th className="price">Price</th>
                                        <th className="quantity">Quantity</th>
                                        <th>Subtotal</th>
                                    </tr>    
                                </thead>
                                <tbody>
                                    {cart.length < 1 ? noCartData() : cartList() }
                                </tbody>    
                            </table>
                        </div>
                    </div>
                </div>
                { isSucess && (<div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                    />
                                    </div>)

                                    }

                                    { isError && (<div>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        </div>)
                                    }

                                <LoadingOverlay
                                    active={disabled}
                                    spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
                                    text='Loading...'
                                >
                                </LoadingOverlay>

                            <div className="cart-total">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12 col-md-12 offset-xl-7 cart-column">
                                        <div className="total-cart-box clearfix">
                                            <h4>Cart Totals</h4>
                                            <ul className="list clearfix">
                                                <li>Subtotal:<span> &#8358;{cartTotalAmount()}</span></li>
                                                <li>Order Total:<span>&#8358;{cartTotalAmount()}</span></li>
                                            </ul>
                                            <Link to={"/checkout"} className="theme-btn-two">Proceed to Checkout<i className="flaticon-right-1"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </section>
        {/* </section> */}
    </div>
  )
}
