import React from 'react'
import {Link} from 'react-router-dom';

export default function Aboutuspreview() {
    return (
        <div>
            <div className="sec-title">
                <h2>About Our Club</h2>
                <div className="separator">
                    <img src="/assets/images/icons/separator-two.png" alt='' />
                </div>
                <div className="text">Metropolitan Club Since 1959</div>
            </div>
            <section className="chooseus-section">
                <div className="outer-container clearfix">
                    <div className="content-column">
                        <div className="inner-column">
                            <div className="content-box">
                                <div className="sec-title style-three">
                                    <p>
                                        About  a  year  before  Nigeria’s  independence  from  British rule on October 1st 1960, Sir Adetokunbo Ademola with four other Nigerians  
                                        founded the  Metropolitan club ,Lagos-“ a social club of gentlemen who are at present, and are likely to continue to be, major contributors 
                                        to the progress of Nigeria.”-Two  of the Nigerians were in the supreme court  with Sir Adetokunbo  Ademola, the chief justice of the federation. 
                                        The other two were the chief medical officer of the federation and the chief medical officer before him, All four Dr. Dele Alakija   and   
                                        Dr. C.M. Norman Williams, Sir Lois Mbanefo and MR. Justice Daddy Onyeama, like Sir, Adetokunbo   Ademola , were old boys of kings  college, Lagos.
                                        The Expatriate (British) nationals were Mr. Robert  Fleming of the Socony (mobil) oil, Mr. E.C. Judd, chairman of U.A.C ,Chief Joe Harold of Ikeja 
                                        arms hotel (now Ikeja airport hotel)and sir Leonard Diary of Barclays Bank.
                                    </p>
                                </div>
                                <div align="center">
                                    <Link to="/aboutus">
                                        <button className="theme-btn-one"> Read More <i className="flaticon-right-1"></i> </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-column" style={{backgroundImage : `url('/assets/images/slider/main.JPG')`}}></div>
                </div>
            </section>
        </div>
    )
}

