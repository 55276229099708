import React, { useState } from "react";
import { Link, Redirect } from 'react-router-dom';

import Footer from '../website/footer';
import Header from '../website/header';
// import { useAuth } from "../../context/auth";
import { Card, Logo, Form, Input, Button, Error, Success } from "./AuthForms";
import DataService from "./services/Service";

import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";



export default function Reset(props)
{
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [isError, setIsError] = useState(false);
	const [confirm_password, setConfirm_password] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState('');
	const [isSucess, setIsSucess] = useState(false);
    const [errors , setErrors] = useState('');

	const [disabled, setDisasbled] = useState(false)

	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");



	const token= props.match.params.id;

	const resetPassword = (e) => {
		e.preventDefault();
		var data = {
			password,
			confirm_password,
			token
		}

		if (typeof password !== "undefined" && typeof confirm_password !== "undefined") {
          
            if (password != confirm_password) {

              setErrors("Passwords don't match.");
            }
          }

		  setDisasbled(true);

		
		DataService.reset(data)
		.then(result => {

			if (result.status === 200) {

			localStorage.setItem("token", result.data.data)

			setLoggedIn(true);

			setMessage(result.data.message)

			setIsSucess(true);

			setDisasbled(false)

		  } else {

			setMessage(result.data.message)

			setIsError(true);
			setDisasbled(false)
		  }
		}).catch(e => {

            setMessage(e.response.data.message)
            setDisasbled(false)
		    setIsError(true);
				
		});
	  }
	

	  if (isLoggedIn) {
		return <Redirect to="/login" />;
	}


    return (
        <div>
            <div id="titlebar" className="gradient">
	<div className="container">
		<div className="row">
			<div className="col-md-12">

				<h2>Reset Password</h2>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li>Reset Password</li>
					</ul>
				</nav>

			</div>
		</div>
	</div>
</div>



<div className="container">
	<div className="row">
		<div className="col-xl-5 offset-xl-3">


			<div className="login-register-page">
				{/* <!-- Welcome Text --> */}
				<div className="welcome-text">
					<h3>Reset Your Password</h3>

				</div>
					
				{isSucess && <Success>{message}</Success>}
				{ isError &&<Error> {message}</Error> }


				<LoadingOverlay
					active={disabled}
					spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
					text='Loading...'
				>
				</LoadingOverlay>

					

				<form onSubmit={resetPassword}>
					<div className="input-with-icon-left">
						<i className="icon-material-baseline-mail-outline"></i>
						<input 
							type="password" 
							className="input-text with-border" 
							name="password" 
							value={password}
							onChange={e => {
								setPassword(e.target.value);
							}}
							placeholder="Password" 
							required
						/>
					</div>

					<div className="input-with-icon-left">
						<i className="icon-material-outline-lock"></i>
						<input 
							type="password" 
							className="input-text with-border" 
							name="confirm_password" 
							value={confirm_password}
							onChange={e => {
								setConfirm_password(e.target.value);
							}}
							placeholder="Confirm Password" 
							required
						/>
					</div>
					
					<button className="button full-width button-sliding-icon ripple-effect margin-top-10" type="submit" disabled={disabled}> Submit <i className="icon-material-outline-arrow-right-alt"></i></button>

				</form>
				
			</div>

		</div>
	</div>
</div>
<div className="margin-top-70"></div>

<Footer />
        </div>
    )
}