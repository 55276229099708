import React, {useMemo, useEffect, useState} from 'react'
import { Link, Redirect } from 'react-router-dom';
import Header from '../website/header'
import DashboardFooter from './dashboardfooter'
import Sidenav from './sidenav'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "./services/Service";
import { Card, Logo, Form, Success, Input, Button, Error } from "./AuthForms";

import {
	Container,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
  } from 'reactstrap';
import TableContainer from "./tablecontainer"
import { SelectColumnFilter } from './filters';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from "react-spinners/BounceLoader";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';



export default function MyPlacedOrders() {

	const [orders, setOrders] = useState([]);

	const [message, setMessage] = useState(false);

	const [isError, setIsError] = useState(false);

	const [isSucess, setIsSucess] = useState(false);

    const [disabled, setDisasbled] = useState(false)


	let [loading, setLoading] = useState(true);
	let [color, setColor] = useState("green");
 


	useEffect(() => {
        let scripts = [
            { src: "/assets/dashboard/js/custom.js" },
        ]

        scripts.map(item => { 
            const script = document.createElement("script")
            script.src = item.src
            script.async = true
            document.body.appendChild(script)
        })    

		DataService.allOrders()
		.then(result => {
			if (result.status === 200) {
				setOrders(result.data.data);

			} else {
				setIsError(true);
			}
			}).catch(e => {
			setIsError(true);
			});

	  
      }, []);



	const deliver = (id) => {

		DataService.deliverOrder(id)
		.then(result => {

		if (result.status === 200) {
			setMessage(result.data.message)

			setIsSucess(true);
 
			setIsError(false);
 
			setDisasbled(false)
 
			window.location.reload()
		   } else {
 
			 setMessage(result.data.message)
 
			 setIsError(true);
 
			 setDisasbled(false)
 
		   }
		 }).catch(e => {
 
			 setMessage(e.response.data.message)
 
		   setIsError(true);
 
		   setDisasbled(false)
 
		 });

    }

		const columns = useMemo(
			() => [
				{
				Header: "name",
				accessor: "user.firstname",
				disableSortBy: true,
				filter: 'equals',
				},
				{
				Header: "Product",
				accessor: "product.name",
				filter: 'equals',
				Filter: SelectColumnFilter,

				},
				{
				Header: "Image",
				accessor: (values) => {

					let result = (<img src={values.product.image} className="img-thumbnail" alt="Cinque Terre" width="100" height="100" />)

					return result
					},
					disableSortBy: true,
					disableFilters: true
	
				},
				{
					Header: "Date",
					accessor: (values) => {
		
						return new Date(values.updated_at).toLocaleDateString();
					},
						disableSortBy: true,
						disableFilters: true
					},
				{
				Header: "Status",
				accessor: (values) => {

					return values.is_delivered == true ? (<button className='btn btn-success btn-sm' >Delivered</button>) : (<button className='btn btn-danger btn-sm'> Pending </button>)
				},
				disableSortBy: true,
				disableFilters: true
				}

			],
			[]
		)

    const renderHeader = () => {
        let headerElement = ['id', 'name', 'email', 'phone', 'product', 'user address','image', 'operation']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const renderBody = () => {
        return orders && orders.map((data) => {
            return (
                <tr key={data.id}>
                    <td>{data.id}</td>
                     <td>{data.user && data.user.firstname ? data.user.firstname : '' }</td>
                     <td>{data.user && data.user.email ? data.user.email : '' }</td>
                     <td>{data.user && data.user.phone ? data.user.phone : '' }</td>
                     <td>{data.user && data.product.name ? data.product.name : '' }</td>
                     <td>{data.user && data.user ? ( <div style={{ color:"black" }}> <h5> Home Address : {data.user.home_address} </h5> <br />  <h5> Office Address : {data.user.office_address} </h5>  </div>) : '' }</td>
                     <td>{data.user && data.product.image ? (<img src={data.product.image} className="img-thumbnail" alt="Cinque Terre" width="100" height="100" />) : '' }</td>
                    <td className='operation'>
						{
							data.is_delivered == true ? (<button className='btn btn-success btn-sm' >Delivered</button>) : (<button className='btn btn-primary btn-sm' onClick={() => deliver(data.id)}>Complete Order</button>)
						}
					</td>
                </tr>
            )
        })
    }

      
      return (
        <div>

            <div className="dashboard-container">

			<Sidenav />
           
		    <div className="dashboard-content-container" data-simplebar>
			<div className="dashboard-content-inner" >
			

			<div className="dashboard-headline">
				<h3>My Ecommerce Orders</h3>

				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><a href="#">Home</a></li>
						<li><a href="#">Dashboard</a></li>
						<li>Orders</li>
					</ul>
				</nav>
			</div>
	

			<div className="row">

				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						<div className="headline">
							<h3><i className="icon-line-awesome-group"></i> My Ecommerce Orders</h3>
						</div>

						<div className="content with-padding padding-bottom-0">

						<LoadingOverlay
							active={disabled}
							spinner={ <BounceLoader color={color} loading={loading}  size={100} /> }
							text='Loading...'
						>
						</LoadingOverlay>


						<Container style={{ marginTop: 70 }}>
							<TableContainer
								columns={columns}
								data={orders}
							/>
							
							{orders.length == 0 ? (<div align="center"> <img src="/assets/images/no_data1.gif" /> </div>) : ""}

						</Container>



						</div>
					</div>
				</div>

						
				
				
				
                </div>
   
   			<DashboardFooter />

		</div>
	</div>
    </div>


        
        </div>
    )
}